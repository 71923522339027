import Vue from 'vue';
import Vuex from 'vuex';
import { pick } from 'ramda';
import persist from 'vuex-persistedstate';

import locale from './store/locale';
import online from './store/online';
// import visibility from './store/visibility';
import auth from './store/auth';
// import sentry from './store/sentry';
// import fullstory from './store/fullstory';
import perms from './store/perms';
import recent from './store/recent';
import recordLive from './store/recordLive';
import recordsPage from './store/recordsPage';
import multiRecords from './store/multiRecords';
import multiQueries from './store/multiQueries';
import moderate from './store/moderate';
import remove from './store/remove';
import updater from './store/updater';
import remover from './store/remover';
import doc from './store/doc';
import docs from './store/docs';

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [
    persist({
      key: 'wotime',
      paths: ['auth.email', 'locale.locale'],
    }),
  ],
  state: {},
  mutations: {},
  actions: {
    login({ dispatch }, { user, claims }) {
      const uid = user.uid;
      const where = claims?.admin
        ? []
        : [['enabledUids', 'array-contains', uid]];
      dispatch('companies/sub', {
        path: ['companies'],
        where,
        orderBy: [['name', 'asc']],
        // reset: true,
      });
      Vue.$fa().setUserProperties(pick(['uid', 'email', 'displayName']));
      Vue.$fa().logEvent('login', pick(['uid', 'email', 'displayName']));
    },
  },
  modules: {
    locale,
    online,
    // visibility,
    auth: auth(),
    // sentry,
    // fullstory,
    perms,
    user: recordLive({}),
    companies: recordsPage({ pageSize: 5, pageSizes: [5] }),
    companiesRecent: recent({}),

    company: recordLive({ timeoutMs: 5000 }),
    orgs: recordsPage({ pageSize: 4, pageSizes: [4] }),
    org: recordLive({ timeoutMs: 5000 }),

    integrations: docs(),
    integration: doc(),

    trail: recordsPage({ pageSize: 10, pageSizes: [10, 25], fieldId: 'tid' }),

    planning: multiQueries({}),
    holidays: recordLive({}),
    cEvents: recordsPage({ pageSize: 4, pageSizes: [4] }),

    companyReportSheets: multiRecords({}),
    companyReportVacations: multiRecords({}),
    companyReportPassages: multiRecords({}),
    companyRights: multiRecords({}),
    moderate,
    remove,
    updater,
    remover,

    accounts: recordsPage({}),
    accountsRecent: recent({}),

    // below are the stores related to a single account user
    account: recordLive({ timeoutMs: 1000 }),
    sheets: recordsPage({ pageSize: 4, pageSizes: [4] }),
    sheetsIndex: doc(),
    // sheet: recordLive({ timeoutMs: 10000 }),
    sheet: doc(),
    events: recordsPage({ pageSize: 4, pageSizes: [4] }),
    event: recordLive({}),
    rights: recordsPage({ pageSize: 4, pageSizes: [4] }),
    right: recordLive({}),
    reportSheets: recordLive({}),
    reportEvents: recordLive({}),
    reportPassages: recordLive({}),
  },
});
