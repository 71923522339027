<template>
  <div
    class="navbar-item has-dropdown is-hoverable"
    v-if="company && isManager"
  >
    <router-link
      class="navbar-link is-arrowless"
      v-if="!aid || !account"
      :to="{ name: 'accounts-org', params: { cid: cid, oid: 'default' } }"
    >
      <span class="icon"><fa :icon="['fal', 'users']" /></span>
      <span>{{ $t('navbar_company_accounts') }}</span>
    </router-link>
    <router-link
      class="navbar-link border-l"
      v-if="aid && account"
      :to="{ name: 'account', params: { cid, aid } }"
    >
      <span class="icon"><fa :icon="['fal', 'user']" /></span>
      <span class="truncate" style="max-width: 10rem">
        <account-name :account="account" />
      </span>
    </router-link>
    <div class="navbar-dropdown is-hidden-mobile">
      <router-link
        class="navbar-item"
        :to="{ name: 'accounts-org', params: { cid: cid, oid: 'default' } }"
      >
        {{ $t('navbar_company_accounts_mgmt') }}
      </router-link>
      <hr class="navbar-divider" />
      <div class="navbar-item uppercase">
        <span class="text-gray-600">
          {{ $t('navbar_company_accounts_recently') }}
        </span>
      </div>
      <router-link
        class="navbar-item"
        v-for="account in recents"
        :key="account.id"
        :to="{ name: 'account', params: { cid: cid, aid: account.id } }"
      >
        <account-name :account="account" reverse />
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import navAccount from './navAccount';
import AccountName from './AccountName';
export default {
  name: 'NavAccounts',
  mixins: [navAccount],
  components: { AccountName },
  computed: {
    ...mapState('company', {
      cid: 'id',
      company: 'record',
      companyLoading: 'loading',
      companyError: 'loadError',
    }),
    ...mapState('accountsRecent', ['recents']),
    ...mapGetters('perms', ['isManager']),
  },
};
</script>
