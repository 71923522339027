import Vue from 'vue';
// import './plugins/sentry'; // not using the format Vue.use

import './assets/tailwind.css';
import './main.scss';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';

Vue.config.productionTip = false;

import firebase from './plugins/firebase';
Vue.use(firebase);

import fa from './plugins/fa';
Vue.use(fa);

import i18n from './i18n';
Vue.$locale = i18n;

import store from './store';
Vue.$store = store;
store.dispatch('init');

Vue.$fa().logEvent('init', {
  version: process.env.VUE_APP_VERSION,
  buildDate: process.env.VUE_APP_BUILD_DATE,
});

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app');
