import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCheck,
  faBox,
  faBoxOpen,
  faArchive,
  faTrash,
  faCaretRight,
  faGlobe,
  faDatabase,
  faUser,
  faUsers,
  faSignIn,
  faUserCog,
  faUserPlus,
  faPlus,
  faExternalLink,
  faExclamationCircle,
  faExclamationTriangle,
  faMagic,
  faBullseye,
  faArrowToTop,
  faExchangeAlt,
  faArrowUp,
  faArrowRight,
  faArrowDown,
  faAngleDown,
  faChevronLeft,
  faChevronRight,
  faChevronDown,
  faSquare,
  faDownload,
  faPlay,
  faTasks,
  faEdit,
  faBell,
  faArrows,
  faCheckCircle,
  faUndo,
  faTimes,
  faCircleNotch,
  faSync,
  // reporting
  faPlusCircle,
  faPlusHexagon,
  faSigma,
  faStethoscope,
  // entries
  faBuilding,
  faCoffee,
  faBriefcase,
  faRecycle,
  faPills,
  faSun,
  // faBell,
  faBaby,
  faChild,
  faWifi,
  faBook, // additional
  faTreeAlt,
  faLeaf,
  faCompass,
  faStickyNote,
  faForward,
  faCalendar,
  faUpload,
  faFile,
  faPaperclip,
  faHistory,
  faSpinner,
} from '@fortawesome/pro-light-svg-icons';
import {
  FontAwesomeIcon,
  FontAwesomeLayers,
  FontAwesomeLayersText,
} from '@fortawesome/vue-fontawesome';

export default function (Vue) {
  // options
  library.add(
    faCheck,
    faBox,
    faBoxOpen,
    faArchive,
    faTrash,
    faCaretRight,
    faGlobe,
    faDatabase,
    faUser,
    faUsers,
    faSignIn,
    faUserCog,
    faUserPlus,
    faPlus,
    faExternalLink,
    faExclamationCircle,
    faExclamationTriangle,
    faMagic,
    faBullseye,
    faArrowToTop,
    faExchangeAlt,
    faArrowUp,
    faArrowRight,
    faArrowDown,
    faAngleDown,
    faChevronLeft,
    faChevronRight,
    faChevronDown,
    faSquare,
    faDownload,
    faPlay,
    faTasks,
    faEdit,
    faBell,
    faArrows,
    faCheckCircle,
    faUndo,
    faTimes,
    faCircleNotch,
    faSync,
    // reporting
    faPlusCircle,
    faPlusHexagon,
    faSigma,
    faStethoscope,
    // entries
    faBuilding,
    faCoffee,
    faBriefcase,
    faRecycle,
    faPills,
    faSun,
    // faBell,
    faBaby,
    faChild,
    faWifi,
    faBook, // additional
    faTreeAlt,
    faLeaf,
    faCompass,
    faStickyNote,
    faForward,
    faCalendar,
    faUpload,
    faFile,
    faPaperclip,
    faHistory,
    faSpinner
  );
  Vue.component('fa', FontAwesomeIcon);
  Vue.component('fal', FontAwesomeLayers);
  Vue.component('falt', FontAwesomeLayersText);
}
