export default {
  locale: 'fr',
  messages: {
    update_banner_title: 'Mise à jour de WoTime',
    update_banner_loading: 'Chargement de la mise à jour',
    update_banner_ready: 'La mise à jour de WoTime est prête',
    update_banner_updating: 'WoTime est en cours de mise à jour',
    update_banner_action: 'Mettre à jour',

    route_loading: 'Chargement…',
    route_home: 'Accueil',
    route_launch: 'Commençons !',
    route_start: 'Quelques questions',
    route_login: "S'identifier",
    route_password_change: 'Changement de mot de passe',
    route_password_reset: 'Définir un mot de passe',
    route_register: 'Créer un compte',
    route_accounts: 'Comptes',
    route_database_dump: 'Sauvegarder la base de données',
    route_company: "Paramètres de l'entreprise",
    route_settings: 'Paramètres',
    route_contract: 'Contract',
    route_planning: 'Planification',
    route_planning_holidays: 'Configurer les Vacances',
    route_requests: 'Demandes (Vacances)',
    route_organisation: 'Hiérarchie',
    route_company_rights: 'Droits aux Vacances',
    route_company_report_sheets: 'Rapport des semaines travaillées',
    route_company_report_vacations: 'Rapport des vacances',
    route_company_report_passages: 'Passages',
    route_subscription: 'Abonnement',
    route_users: 'Utilisateurs',
    route_user_add: 'Ajouter un utilisateur',
    route_user_edit: "Mise à jour de l'utilisateur",
    route_user: 'Utilisateur',
    route_sheets: 'Semaines',
    route_sheet: 'Semaine de travail',
    route_passages: 'Passages',
    route_events: 'Calendrier',
    route_event_add: 'Ajouter un événement',
    route_events_holidays: 'Vacances',
    route_event_edit: "Mise à jour de l'événement",
    route_rights: 'Droits de vacances',
    route_right_add: 'Ajouter un droit',
    route_right_edit: 'Mettre à jour un droit',
    route_report_sheets: 'Rapport des feuilles de temps',
    route_report_vacations: 'Rapport des vacances',
    route_report_passages: 'Passages',
    route_about: 'À propos',
    route_logout: 'Déconnexion',
    route_not_found: 'Fonction introuvable',
    navbar_companies: 'Sociétés',
    navbar_companies_mgmt: 'Voir les sociétés',
    navbar_company_management: 'Management',
    navbar_company_planning: 'Planification',
    navbar_company_requests: 'Demandes',
    navbar_company_rights: 'Droits aux Vacances',
    navbar_company_report_sheets: 'Rapport de travail',
    navbar_company_report_vacations: 'Rapport des vacances',
    navbar_company_report_passages: 'Rapport des passages',
    navbar_company_subscription: 'Abonnement',
    navbar_company_settings: "Paramètres de l'entreprise",
    navbar_company_accounts: 'Comptes',
    navbar_company_accounts_mgmt: 'Gérer les comptes utilisateurs',
    navbar_company_accounts_recently: 'Récents',
    navbar_users: 'Gestion des utilisateurs',
    navbar_account_sheets: 'Semaines de travail',
    navbar_vacation_rights: 'Droits aux vacances',
    navbar_passages: 'Passages',
    navbar_account_calendar: 'Événements',
    navbar_rights: 'Droits aux vacances',
    navbar_account_reports: 'Rapports',
    navbar_report_work: 'Rapport de travail',
    navbar_report_vacations: 'Rapport des vacances',
    navbar_report_passages: 'Rapport des passages',
    navbar_settings: 'Paramètres',
    navbar_account: 'Paramètres du compte',
    navbar_sign_in: 'Se connecter',
    navbar_sign_up: 'Créer un compte',
    navbar_sign_out: 'Déconnexion',
    nav_companies_recent: 'Récents',
    nav_user_password_change: 'Changer de mot de passe',
    nav_user_about: 'À propos de WoTime',

    breadcrumb_home: 'WoTime',
    breadcrumb_companies: 'sociétés',
    breadcrumb_company_planning: 'planning',
    breadcrumb_company_settings: 'paramètres',
    breadcrumb_company_name: 'nom',
    breadcrumb_company_defaults: 'comptes : paramètres par défaut',
    breadcrumb_company_models: 'modèles des inscriptions du temps ',
    breadcrumb_company_extensions: 'extensions',
    breadcrumb_company_holidays: 'jours fériés',
    breadcrumb_company_requests: 'demandes',
    breadcrumb_company_rights: 'droits aux vacancess',
    breadcrumb_company_report_work: 'rapport de travail pour la société',
    breadcrumb_company_report_vacations: 'rapport de vacance pour la société',
    breadcrumb_company_report_passages: 'rapport des passages',
    breadcrumb_orgs: 'structures',
    breadcrumb_accounts: 'comptes',
    breadcrumb_account_add: 'ajouter un compte',
    breadcrumb_account_sheets: 'semaine de travail',
    breadcrumb__: 'événements calendaires',
    breadcrumb_rights: 'droits aux vacances',
    breadcrumb_reports: 'rapports',
    breadcrumb_report_sheets: 'travail',
    breadcrumb_report_vacations: 'vacances',
    breadcrumb_settings: 'paramètres',
    breadcrumb_permissions: "rôles et droits d'accès",
    buttons_back: 'Retour',
    buttons_cancel: 'Annuler',
    buttons_save: 'Sauvegarder',
    buttons_continue: 'Continuer',
    buttons_remove: 'Supprimer',
    buttons_load: 'Charger',
    buttons_reset: 'Réinitialiser',
    progress_loading: 'Chargement en cours…',
    progress_saving: 'Sauvegarde en cours…',
    home_sign_in: 'Connexion à WoTime',
    home_sign_up: 'Créer un nouveau compte',
    companies_start: 'Ajouter une société',
    company_card_sheets: 'Semaines',
    company_card_account: 'Compte',
    company_management_planning: 'Planning',
    company_planning: 'Planning',
    company_planning_help:
      'voir les événements calendaires pour tous les employés',
    company_requests: 'Demandes',
    company_requests_help: 'Accorder ou refuser les événements calendaires',
    company_holidays: 'Jours fériés',
    company_holidays_help:
      'Ajouter les jours fériés aux calendriers de chaque compte par canton',
    company_rights: 'Droits aux vacances',
    company_rights_help:
      'Mettre à jour tous les droits aux vacances de tous les employés en une fois',
    company_requests_none: 'Aucune demande en cours',
    notify_event_past_title: 'Événement du passé',
    notify_event_past_message:
      "Vous avez mis à jour un événement dans le passé, les semaines de travail correspondantes ont déjà été créées. N'oubliez pas de mettre à jour les feuilles de travail correspondantes.",
    start_company_title: 'Commencez à utiliser WoTime',
    start_company_subtitle:
      'Créer un compte pour votre société ou un compte personnel',
    start_company_name_placeholder: 'Nom de la société',
    start_company_work_model: 'Je devrais travailler',
    start_company_work_model_42: '42 heures par semaine du lundi au vendredi',
    start_company_work_model_41: '41 heures par semaine du lundi au vendredi',
    start_company_work_model_40: '40 heures par semaine du lundi au vendredi',
    start_company_work_model_other: 'autre…',
    start_company_weekdays_worked: 'Jour de la semaine travaillés',
    start_company_week_due:
      "Combien d'heures de travail, d'après le contrat, sont dûes par semaine ?",
    start_company_week_due_placeholder: 'hh:mm',
    start_company_week_due_help:
      'Généralement 40:00, 41:00 ou 42:00 pour un temps plein',
    start_company_week_conv: "Combien d'heures vaut une journée de travail ?",
    start_company_week_conv_help:
      'Si votre travail dure 40:00 heures par semaine (5 jours) un jour vaut 08:00 heures. <br> Si votre travail dure 41:00 heures par semaine (5 jours) un jour vaut 08:12 heures. <br> Si votre travail 42:00 heures par semaine (5 jours) un jour vaut 08:24 heures.',
    start_company_week_conv_placeholder: 'hh:mm',
    start_company_vacations:
      'De combien de jours de vacance, par année, avez-vous droit ?',
    start_company_vacations_placeholder: 'jours',
    start_company_vacations_help: 'Générallement 20, 25 ou 30 jours par année',
    start_company_confirm: 'Commencer à utiliser WoTime !',

    start_name: 'Votre nom',
    start_given_name: 'Prénom',
    start_family_name: 'Nom de famille',
    start_mode: 'Comment allez-vous utiliser WoTime?',
    start_mode_company: 'en tant que société',
    start_mode_company_help:
      "plus d'un employé fera rapport sur leur temps WoTime",
    start_mode_personal: 'compte personnel',
    start_mode_personal_help: 'Je serai la seule personne de suivi mon temps',
    start_company_name: 'Le nom de votre société?',
    start_personal_title: 'Commençons',
    start_personal_subtitle:
      'Afin de suivre votre temps de travail et les vacances nous avons besoin de quelques réponses',
    start_personal_email: 'Votre adresse email',
    start_personal_email_help:
      'pour que vous puissiez récupérer vos données en cas de perte de votre mot de passe',
    start_personal_vacations:
      'Combien de jours de vacances avez-vous par année ?',
    start_personal_work_time_due: "Combien d'heures de travail par semaine ?",
    start_personal_conversion: "Combien d'heures de travail dans une journée ?",
    start_personal_confirm: 'Commencez à utiliser WoTime!',
    start_profile_title: 'À propos de vous',
    signin_title: 'Se connecter à WoTime',
    signin_subtitle: 'En cas de problème, contactez le support',
    signin_email: 'email',
    signin_password: 'mot de passe',
    signin_sign_in: 'Connexion',
    signin_sign_up: 'Créer un compte ?',
    signin_lost: 'Mot de passe perdu ?',
    password_lost_title: 'Mot de passe perdu',
    password_lost_email: 'email',
    password_lost_email_help:
      'Vous allez recevoir un email avec lien pour réinitialiser votre mot de passe',
    password_lost_confirm: 'Envoyer le lien',
    password_lost_success: 'Veuillez vérifier votre boite à lettre',

    password_change_title: 'Changer de mot de passe',
    password_change_subtitle:
      'Veuillez saisir votre ancien mot de passe, votre nouveau mot de passe et confirmer le noveau mot de passe encore une fois',
    password_change_password_old: 'ancien mot de passe',
    password_change_password_new: 'nouveau mot de passe',
    password_change_password_confirm: 'confirmation du nouveau mot de passe',
    password_change_confirm: 'Changer le mot de passe',
    password_change_success: 'Votre mot de passe a bien été changé',

    signup_title: 'Créer un compte',
    signup_subtitle:
      'Veuillez saisir votre adresse email et choisissez un mot de passe',
    signup_email: 'email',
    signup_email_required: 'Saisir une adresse email',
    signup_email_invalid: "Vérfier l'adresse email",
    signup_password: 'mot de passe',
    signup_password_required: 'Choisir et saisir un mot de passe',
    signup_password_length_min:
      'Le mot de passe doit compter huit car atères ou plus',
    signup_given_name: 'prénom',
    signup_given_name_required: 'Saisir votre prénom',
    signup_family_name: 'nom de famille',
    signup_family_name_required: 'Saisir votre nom de famille',
    signup_sign_in: 'Vous avez déjà un compte ?',
    signup_sign_up: 'Créer mon compte',
    tasks_title: 'Taches',
    tasks_subtitle:
      "Les taches ci-dessous vous permette d'effectuer les maintenances de votre base de données.",
    tasks_continue: 'Continuer',
    tasks_launch: 'Lancer la Tache',
    task_sheets_generate_title: 'Générer les semaines de travail',
    task_sheets_generate_subtitle:
      'Générer des semaines de travail à partir des passages et des événements des calendriers',
    task_sheets_generate_weeks: 'Nombre de semaine dans la passé à générer',
    task_sheets_generate_loaded: 'Chargé {count} {type}',
    task_sheets_generate_found: 'Trouvé une semaine de travail existante {id}',
    task_sheets_generate_new: 'Calcul de la semaine de travail {id}',
    task_sheets_generate_unmodified: 'Semaine de travail {id} non modifiée',
    task_sheets_generate_updated: 'Semaine de travail {id} mise à jour',
    task_sheets_compute_title: 'Recalculer les semaines de travail',
    task_sheets_compute_subtitle:
      'Pour tous les utilisateurs, recalculer les totaux des semaines de travail.',
    task_sheets_compute_weeks: 'Nombre de semaines dans le passé à recalculer',
    planning_title: 'Planification',
    planning_subtitle: 'Tous les événements utilisateurs à un seul endroit',
    planning_current: 'mois actuel',
    planning_accounts: 'Comptes',
    planning_org: 'Structure',
    planning_holidays: 'Ajouter des jours fériés à la planification',
    planning_month_current: 'mois actuel',
    planning_retro: 'événements passés depuis les semaines de travail',

    company_report_work_sheets_count: 'Nombre de feuilles',
    company_report_work_sheets_accepted: 'Acceptées',
    company_report_work_sheets_warning: 'avec des avertissements',
    company_report_work_sheets_error: 'avec des erreurs',
    company_holidays_year_choose: 'Choisissez une année',
    company_holidays_state_choose: 'Choisissez un canton',
    company_holidays_days_choose: 'Choisissez les jours fériés',
    company_holidays_days_help:
      'Les samedis et dimanches ne sont pas sélectionnés. Ne les ajouter que si votre société les considère comme des jours travaillés.',
    company_holidays_accounts_choose:
      'Choisissez les comptes recevants les jours fériés sélectionnés',
    company_holidays_added: '✅ Jours fériés ajoutés au planning',
    retro_planning_planning: 'Planning',
    requests_title: "Calendrier Demandes d'événements",
    requests_subtitle: 'Vous pouvez marquer les événements comme acceptés',
    requests_empty: 'Toutes les demandes ont été traitées',
    org_title: 'Structure',
    org_subtitle: 'Organisez les comptes utilisateurs et les équipes',
    org_name: 'Nom',
    org_team_add: 'Ajouter une équipe',
    org_management_sheets_report_overtime_hide:
      'Cacher les heures supplémentaires',
    org_management_sheets_report_overtime_hide_help:
      'Ne pas montrer les inscriptions de temps dans les rapports de la société',
    org_dropzone: 'Déposer un élément ici',
    org_dropzone_remove:
      'Déposer un élément ici pour le retirer de la structure',
    company_rights_title: 'Droits aux Vacances',
    company_rights_subtitle:
      'Mettre à jour tous les droits aux vacances pour la même année',
    company_rights_account: 'Compte',
    company_rights_week_year: 'Année',
    company_rights_name: 'Nom',
    company_rights_week_start: 'De la semaine',
    company_rights_week_end: "Jusqu'à la semaine",
    company_rights_previous: 'Report',
    company_rights_contract: 'par Contrat',
    company_rights_total: 'Total',
    company_rights_accepted: 'Accepté',
    company_rights_carry_title:
      "Report des soldes de vacances l'année précédente",
    company_rights_actions: 'Actions',
    company_rights_create: 'Sauver',
    company_rights_new: 'Créer un nouveau droit',
    company_rights_carry: 'Report',
    company_report_sheets_title: 'Rapport de travail',
    company_report_sheets_title_alt:
      'Rapport de travail [{startWeek, endWeek}]',
    company_report_sheets_subtitle:
      'Tableau des compteurs des semaines de travail',
    company_report_sheets_changes: 'Des semaines de travail ont été changées',
    company_report_sheets_reprocess: 'Mettre à jour le rapport',
    company_report_sheets_employees: 'Employés',
    company_report_sheets_sheets: 'Semaines',
    company_report_sheets_accepted: 'Acceptées',
    company_report_sheets_warning: 'Avertissements',
    company_report_sheets_error: 'Erreurs',
    company_report_work_from: 'De la semaine',
    company_report_work_until: 'à la semaine',
    company_report_work_build: 'Reconstuire le rapport',
    company_report_vacations_week_from: 'Depuis la semaine',
    company_report_vacations_week_until: "Jusqu'à la semaine",
    company_report_vacations_planned_until: "Vacances planifiées jusqu'au",
    company_report_vacations_build: 'Constuire le rapport',
    company_report_vacations_title: 'Rapport des vacances',
    company_report_vacations_title_alt:
      'Rapport des vacances du {weekStart} au {weekEnd}',
    company_report_vacations_subtitle:
      'Résumé des droits des vacances restants',
    company_report_vacations_changes:
      'Des semaines de travail ou des droits aux vacances ont changés',
    company_report_vacations_reprocess: 'Mettre à jour le rapport',
    company_report_vacations_employees: 'Employés',
    company_report_vacations_sheets: 'Semaines',
    company_report_vacations_accepted: 'Acceptées',
    company_report_vacations_initial: 'Initial',
    company_report_vacations_used: 'Utilisé',
    company_report_vacations_remaining: 'Restant',
    company_report_vacations_edit: 'Modifier tous les droits',
    company_report_vacations_prepare:
      "Préparer les droits aux vacances de l'année suivante",
    company_report_passages_title: 'Passages',
    company_report_passages_subtitle: 'Vérifiez les passages pour une journée',
    company_report_passages_changes: 'Des passages ont été ajoutés',
    company_report_passages_reprocess: 'Mettre à jour',
    company_report_passages_date: 'Date des passages',
    company_report_passages_employees: 'Employés',
    company_report_passages_passages: 'Passages',
    company_report_passages_ignored: 'Incohérences',
    company_report_passages_work_week: 'Semaine de Travail',
    company_report_passages_events: 'Calendrier',
    subscription_title: 'Abonnement',
    subscription_subtitle:
      'Vérifier les détails de votre abonnement, les factures et vos moyens de paiement',
    subscription_subscription: 'Abonnement',
    subscription_status: 'État',
    subscription_cost: 'Coût',
    subscription_users_current: 'Utilisateurs',
    subscription_user: 'utilisateur',
    subscription_month: 'mois',
    subscription_users:
      'utilisateur actif dans le mois | utilisateurs actifs dans le mois',
    subscription_trial_period: "Période d'essai",
    subscription_trial_period_days: 'jours',
    subscription_trial_end: "Fin de la période d'essai",
    subscription_warranty: 'Garantie',
    subscription_warranty_text: '100 % satisfait ou remboursé',
    subscription_terms: 'afficher les conditions générales de vente',
    subscription_invoices_title: 'Factures',
    subscription_invoices_number: 'Numéro',
    subscription_invoices_date: 'Date',
    subscription_invoices_status: 'État',
    subscription_status_past_due: 'échu',
    subscription_invoices_status_paid: 'payée',
    subscription_invoices_due_date: 'Due',
    subscription_invoices_period_start: 'Période du',
    subscription_invoices_period_end: "jusqu'au",
    subscription_invoices_amount_due: 'Montant Dû',
    subscription_invoices_amount_paid: 'Montant Payé',
    subscription_invoices_currency: 'Devise',
    subscription_cc_title: 'Cartes de Crédit',
    subscription_cc_brand: 'Marque',
    subscription_cc_number: 'Numéro',
    subscription_cc_expires: 'Expiration',
    subscription_cc_usage: 'Utilisation',
    subscription_cc_actions: 'Actions',
    subscription_cc_default: 'utilisé pour le débit',
    subscription_cc: 'Carte de Crédit',
    subscription_cc_add: 'Ajouter la carte de crédit',
    subscription_cc_add_error:
      'Erreur temporaire en ajoutant la carte de crédit',
    subscription_cc_remove: 'Retirer',
    terms_title: 'Conditions Générales',
    terms_subtitle:
      "Les conditions générales sont en français uniquement. Veuillez nous contacter si vous avez besoin d'une traduction.",
    company_tag: 'Société',
    company_title: "Paramètres de l'entreprise",
    company_management: 'Gestion',
    company_reports: 'Rapports de gestion',
    company_report_sheets: 'Rapport de travail',
    company_report_sheets_help:
      'Résumé de toutes les semaines de travail pour tous les employés',
    company_report_vacations: 'Rapport des vacances',
    company_report_vacations_help:
      'Résumé des vacances prises, planifiées et restantes pour chaque employé',
    company_report_passages: 'Rapport des passages',
    company_report_passages_help: 'Vérifier les passages de tous les employés ',
    company_audit_trail: "Piste d'audit",
    company_audit_trail_help: 'Énumération de tous les changements',
    company_accounts_title: 'Comptes',
    company_accounts: 'Gérer les comptes',
    company_accounts_help:
      "Ajouter un nouveau compte, changer les rôles et les droits d'accès",
    company_orgs: 'Structures',
    company_orgs_help: 'Organiser les comptes en équipes',
    company_org: 'Structure',
    company_org_help: 'Organiser les comptes en équipes',
    company_settings: "Paramètres de l'entreprise",
    company_settings_help:
      "Mettez à jour le nom de votre entreprise, le modèle de saisie d'heure et les extensions",
    company_settings_name: 'Nom de la société',
    company_settings_name_help: 'Changer le nom de la société',
    company_settings_defaults: 'Paramètre pour les nouveaux comptes',
    company_settings_defaults_help:
      'Les paramètre par défault utilisés lors de la création de nouveauc comptes tel que les paramètre de semaine et des vacances',
    company_settings_models: "Les modèles d'inscription du temps",
    company_settings_models_help:
      "Changer la présentation des modèles d'inscription du temps",
    company_settings_extensions: 'Les extentions',
    company_settings_extensions_help: 'Changer la comptabilisation du temps',
    company_subscription: 'Abonnement',
    company_subscription_help: 'Moyens de paiement et factures',
    company_name: 'Nom de votre entreprise',
    company_contract_form: 'Forme sociale',
    company_contract_capital: 'Capital',
    company_contract_ide: 'IDE',
    company_contract_street: 'Rue',
    company_contract_postal_code: 'Code postal',
    company_contract_city: 'Ville',
    company_contract_contact: 'Contractant',
    company_contract_position: 'Fonction',
    company_contract_label: 'Libellé du produit',
    company_contract_price: 'Prix',
    company_contract_email: 'email',
    company_contract_currency: 'Devise',
    company_contract_created: 'Création',
    company_extension_overlaps:
      'Vérifiez chevauchement des entrées de la semaine de travail',
    company_extension_overlaps_help:
      'Vérifiez si deux entrées de des semaines travaillées se chevauchent.',
    company_extension_ltr15: 'Article 15',
    company_extension_ltr15_help:
      "Appliquer des pauses obligatoires décrites dans l'article 15 de la loi du travail suisse (LTr)",
    'company_extension_ltr15-wc': 'Article 15 with margins (Webcor)',
    'company_extension_ltr15-wc_help':
      'Apply mandatory pauses described in article 15 of Swiss Labor Law with some margins. When work > 9.5 hours then 1 hour of pause, when work > 7.5 hours then 30 minutes of pause, and when work > 5.75 hours -> 15 minutes of pause',
    company_entry_models: 'Entrées de feuille de temps',
    company_entry_models_help:
      'Personnaliser les couleurs des entrées de temps qui sont affichées sur la planification et dans la vue semaine de travail',
    company_extensions: 'extensions',
    company_extensions_help:
      "Choisissez quelle extension s'appliquera au calcul de la feuille de temps. Si vous avez besoin d'un autre poste s'il vous plaît contactez-nous.",
    company_account_week_settings: 'Paramètre des nouvelles semaines',
    company_account_week_settings_help:
      'Quand un nouveau compte est créé, les paramètre de la semaine ci-dessous sont repris.',
    trail_timestamp: 'Quand',
    trail_user: 'Qui',
    trail_account: 'Compte',
    trail_record: 'Collection',
    trail_id: 'Enregistrement',
    users_title: 'Gestion des utilisateurs',
    users_subtitle: "Ajouter un nouvel utilisateur, donner l'accès utilisateur",
    user: 'Utilisateur',
    accounts_org: 'Structurer les comptes utilisateurs',
    accounts_org_view: 'Structure',
    accounts_org_all: 'Tous les comptes utilisateurs',
    accounts_settings: 'Paramètres',
    accounts_archive: 'Archiver',
    weekday_entry_from: 'de',
    weekday_entry_until: 'à',
    weekday_entry_day: 'jour',

    account_sheets: 'Semaine de travail',
    account_sheets_help: 'Inscire et vérifier votre temps de travail',
    account_events: 'Événements calendaires',
    account_events_help: 'Planifier les vacances et vérifier les jours fériés',
    account_rights: 'Droits aux vacances',
    account_rights_help:
      "Enregistrer les droits de vacances par contrat et restant de l'année dernière",
    account_reports: 'Rapports',
    account_report_work: 'Rapport de travail',
    account_report_work_help: "Résumé de tous vos compteurs pour l'année",
    account_report_vacations: 'Rapport des vacances',
    account_report_vacations_help: "Récapitulatif de vos vacances pour l'année",
    account_report_passages: 'Rapport des passages',
    account_report_passages_help:
      'Voyez quand vous entrez et sortez du travail',
    account_settings: 'Paramètres',
    account_settings_help:
      'Modifier les paramètres généraux, hebdomadaires et annuels',
    account_permissions: "Rôles et droits d'accès",
    account_permissions_help:
      "Rôle de gestionnaire, rôle d'employé, accès en lecture et en écriture",
    account_add_title: 'Ajouter un utilisateur',
    account_add_subtitle: 'Inviter un utilisateur',
    account_add_email: 'email',
    account_add_given_name: 'Prénom',
    account_add_family_name: 'Nom de famille',
    account_add_email_help:
      "L'utilisateur recevra un e-mail l'invitant à WoTime en votre nom. Cet e-mail contient des instructions sur la façon de se connecter.",
    account_add_uid: 'Matricule (uid)',
    account_add_password: 'Mot de passe',
    account_add_confirm: 'Ajouter le compte',
    accounts_add: 'Ajouter un compte',
    users_view_archived: 'Voir les utilisateurs archivés',
    sheets_title: 'Semaines de travail pour {name}',
    sheets_subtitle:
      'Semaines de travail vous permettent de suivre par semaine vos périodes de travail et les vacances',
    sheets_view_archived: 'Voir semaines de travail archivé',
    sheets_index_refresh: 'Indexer',
    sheet_card_work_week: 'Semaine de travail',
    sheet_card_from: 'du',
    sheet_card_to: 'au',
    sheet_card_accepted: 'Acceptée',
    sheet_remove_confirm:
      'Voulez-vous vraiment supprimer la semaine de travail {name}?',
    sheet_settings_due: 'Travail dû',
    sheet_settings_due_cap:
      "Êtes-vous sûr ? Cette valeur est supérieure au travail dû habituel d'une semaine.",
    sheet_settings_max: 'Travail maximum',
    sheet_settings_max_cap:
      'Êtes-vous sûr? Cette valeur est supérieure à la durée maximale légale (entre 40 et 45 heures).',
    sheet_settings_conv: 'Un jour correspond à',
    entry_span_from: 'hh:mm',
    entry_span_until: 'hh:mm',
    entry_model_modal_filter: 'rechercher les modèles ...',
    entry_note: 'Note',
    entry_model_picker_search: 'rechercher…',
    period_buttons_label: 'Périodes',
    sheets_work_week: 'Semaine de travail',
    sheets_accept: 'Accepter',
    sheets_archive: 'Archiver',
    sheets_unarchive: 'Désarchiver',
    sheets_create_first: 'Créer la première semaine de travail',
    sheets_create_next: 'Ajouter la semaine de travail {week}',
    sheets_create_previous: 'Ajouter semaine de travail {week}',
    sheet_title: 'Semaine de travail',
    sheet_subtitle:
      'Décrivez vos périodes de travail, les pauses, les vacances et les jours fériés',
    sheet_validated:
      'Cette semaine de travail est marquée comme validée. Si vous modifiez la semaine, elle perdra sa marque de validation.',
    sheet_copy_previous: 'Copier les entrées de précédent',
    sheet_generate: 'Rafraîchir les entrées',
    sheet_generate_tooltip: 'Générer à partir des passages et du calendrier',
    sheet_versions: 'Versions antécédentes',
    account_sheet_create: 'Créer la semaine de travail',
    update_info_by: 'mise à jour par',
    update_info_on: 'au',
    sheet_revs_title: 'Versions',
    sheet_revs_subtitle:
      'Vous pouvez revenir à une version antécédente de la semaine de travail.',
    sheets_revs_rev: 'Afficher la version',
    sheet_revs_restore: 'Restaurer la version',
    week_day_more: 'plus',
    week_total: 'Totaux',
    sheet_summary_inputs: 'Ce qui a été saisi dans cette feuille',
    sheet_summary_computed: 'Et ce qui a été calculé pour cette feuille',
    week_summary: 'Résumé',
    week_settings: 'Paramètres Semaine',
    entry_duration_placeholder: 'hh:mm',
    events_title: 'Événement du Calendrier',
    events_subtitle:
      'Le Calendrier vous aides à planifier vos événements et plus tard à remplir vos feuilles',
    events_add: 'Ajouter un événement',
    events_planning: 'Planning',
    event_add_vacations: 'Vacances',
    events_holidays_add: 'Ajouter les jours fériés de votre canton',
    events_view_past_events: 'voir les événements passés',
    event_tag: 'Événement',
    event_card_event: 'Événement',
    event_card_accept: 'Accepter',
    holidays_title: 'Ajouter les jours fériés de votre canton',
    holidays_subtitle: 'Sélectionner le canton',
    holidays_info_single:
      'Vous ajoutez des jours fériés pour un seul utilisateur.',
    holidays_info_also: 'Vous pouvez également ajouter un jour férié {0}.',
    holidays_info_all: 'tous les utilisateurs',
    holidays_state: 'Choisissez un canton',
    holidays_year: 'Année',
    holidays_add_all: 'Ajouter tous les jours fériés énumérés ci-dessous',
    event_holiday_choose: 'Choisissez un canton',
    events_holidays_check_title: 'Jour fériés',
    events_holidays_check_subtitle:
      'Les jour travaillés et jours chômés pour tous les cantons',
    events_holidays_check_label: 'Libellé',
    events_holidays_check_date: 'Date',
    events_holidays_check_type: 'Type',
    event_per_day: 'par jour',
    event_title: 'Calendrier des événements',
    event_subtitle:
      'Calendrier des événements aider à planifier et plus tard remplir vos semaines de travail',
    event_save: 'Enregistrer',
    event_remove: 'Supprimer',
    event_remove_confirm: "Voulez-vous vraiment supprimer l'événement {name}?",
    event_remove_all: 'Supprimer tous',
    event_all_day: "Événement d'une journée",
    event_all_day_text: "Toute la journée peut s'étendre à plusieurs jours",
    event_modal_all_accounts_create: 'Créer pour tous les comptes',
    event_modal_all_accounts_create_description:
      'créer un événement pour tous les comptes',
    event_account: 'Compte',
    event_start_date: 'Du',
    event_end_date: "Jusqu'au",
    event_start_time: 'de',
    event_end_time: "jusqu'à",
    event_entry: 'Type',
    event_entry_pick: "Choisissez un modèle d'inscription",
    event_weekday_exception: 'Exclure',
    event_weekday_exception_mon: 'Lun',
    event_weekday_exception_tue: 'Mar',
    event_weekday_exception_wed: 'Mer',
    event_weekday_exception_thu: 'Jeu',
    event_weekday_exception_fri: 'Ven',
    event_weekday_exception_sat: 'Sam',
    event_weekday_exception_sun: 'Dim',
    event_attachments: 'Pièces jointes',
    event_attachments_upload: 'Téléverser une pièce jointe',
    event_note: 'Note',
    event_status: 'Statut de validation',
    right_card_label: 'Droit aux vacances',
    rights_title: 'Droits aux vacances',
    rights_subtitle:
      'Droits de vacances vous permettent de suivre votre utilisation de vacances par période',
    right_from: 'du',
    right_until: "jusqu'au",
    right_part_from: 'depuis',
    right_part_until: "jusqu'au",
    right_part_days: 'jours',
    right_part_base: 'base de calc.',
    right_part_vacations: 'congés',
    right_part_add: 'ajouter une période',
    rights_add: 'Ajouter un droit de vacances',
    right: 'droit aux vacances',
    right_edit_title: 'Droit aux Vacances',
    right_edit_subtitle:
      'Les droits aux vacances vous permettent de suivre votre utilisation de vacances par année',
    right_start_date: 'De',
    right_end_date: "Jusqu'à",
    right_week_year: 'Année',
    right_vacations_contract: "Droit aux vacances pour l'année",
    right_vacations_contract_short: 'Période',
    right_vacations_previous:
      "Report des droits aux vacances de l'année précédente",
    right_vacations_previous_short: 'précédent',
    right_vacations: 'droit total de vacances',
    right_compute: 'Calculer',
    right_vacations_short: 'Total',
    right_vacations_computed: 'Calculé',
    right_note: 'Note',
    right_save: 'Enregistrer droit',
    right_remove: 'Retirer',
    reports_actions: 'Périodes',
    reports_summaries: 'Les travaux résumés Semaine',
    reports_vacations: 'Les vacances',
    reports_passages: 'passages',
    report_sheets_title: 'Rapport des semaines travaillées',
    report_sheets_subtitle: 'Résumer des compteurs des semaines travaillées',
    report_sheets: 'Rapport des semaines travaillées',
    report_sheets_start_date: 'de la semaine',
    report_sheets_end_date: 'à la semaine',
    report_sheets_details: 'Détails',
    report_sheets_details_help: 'afficher les entrées des semaines',
    report_sheets_sheets: 'semaines',
    report_sheets_accepted: 'accepté',
    report_sheets_from: 'de',
    report_sheets_until: "jusqu'au",
    report_vacations_title: 'Rapport des vacances',
    report_vacations_for: 'pour',
    report_vacations_subtitle: 'Résumé des droits des vacances restants',
    report_vacations_sheets: 'semaines',
    report_vacations_start_week: 'de semaine',
    report_vacations_end_week: "jusqu'à la semaine",
    report_vacations_end_right: "vacances plannifiées jusqu'au",
    report_vacations_end_right_help:
      'les vacances plannifiées et pas encore utilisées',
    report_vacations_details: 'Détails',
    report_vacations_details_help:
      'afficher les entrées utilisées pour les calculs',
    report_vacations: 'Rapport Vacances',
    report_vacations_start_date: 'De',
    report_vacations_end_date: "Jusqu'à",
    report_vacations_right: 'Droits aux vacances',
    report_vacations_usage: 'Usage',
    report_vacations_week: 'Semaine',
    report_vacations_day: 'Jour',
    report_vacations_amount: 'Montant',
    report_vacations_note: 'Note',
    report_vacations_op_right: 'Droit aux vacances',
    report_vacations_op_used: 'Utilisés',
    report_vacations_op_planned: 'Planifiés',
    report_vacations_event_date: 'Date',
    report_vacations_event_amount: 'Vacances',
    report_vacations_event_note: 'Note',
    report_vacations_op_remaining: 'Restant',
    report_passages_title: 'Rapport des passages',
    report_passages_subtitle: 'Vérifiez les passages pour la semaine en cours',
    report_passages_week: 'La semaine',
    report_passages_not_found:
      "Aucun passage n'a été trouvé pour cette semaine.",
    account_title: 'Compte',
    account_title_alt: 'Compte {firstName} {lastName}',
    account_general: 'Général',
    account_given_name: 'Prénom',
    account_family_name: 'Nom de famille',
    account_email: 'email',
    account_email_help:
      "pour changer l'adresse email contacter le support technique de WoTime",
    account_role: 'Rôle',
    account_roles_user: 'employé(e)',
    account_roles_user_help: "ne peut accéder qu'à ses propres données",
    account_roles_manager: 'gérant',
    account_roles_manager_help:
      'peut accéder à toutes les données des employés',
    account_access: 'Accès',
    account_accesses_rw: 'lecture & écriture',
    account_accesses_rw_help: 'peut afficher et mettre à jour les données',
    account_accesses_ro: 'lecture seule',
    account_accesses_ro_help: 'ne peut voir que les données',
    account_planning: 'Planning',
    account_planning_help: "Accès en lecture seule au planning de l'entreprise",
    account_week_settings: 'Paramètres des futures semaines de travail',
    account_week_settings_simple: 'simples',
    account_week_settings_alternate: 'alternées',
    account_week_settings_help:
      'Quand un nouveau compte est créé, les paramètre de la semaine ci-dessous sont repris.',
    account_week_settings_due: 'Travail dû',
    account_week_settings_due_odd:
      'Travail dû pour les semaines impaires (W01, W03…)',
    account_week_settings_due_even:
      'Travail dû pour les semaines paires (W02, W04…)',
    account_week_settings_max: 'Travail maximum par semaine',
    account_week_settings_max_helper:
      'Généralement 45:00 ou définit une collection collective',
    account_week_settings_conv: 'Une journée de travail vaut',
    account_year_settings: 'Paramètres des futures années',
    account_year_settings_help:
      "Les valeurs ci-dessous seront utilisées lors de la création d'un nouveau droit au travail",
    account_year_settings_vacations: 'Vacances par année',
    account_keycards_settings: 'Carte Clés',
    account_keycards_settings_help:
      'Créer automatiquement des passages à partir des passages des cartes-clés aux portiques',
    account_keycards: 'Cartes-clés',
    account_keycards_help: 'Saisir un nom ou numéro de carte clé par ligne',
    passages_title: 'Passages',
    passages_subtitle:
      'Obtenez votre temps de travail remplir automatiquement en mettant en place des passages de votre iPhone ou téléphone Android.',
    passages_phone_connect:
      "Vous devez être connecté à l'application sur votre téléphone pour configurer les passages",
    settings_title: 'Paramètres',
    settings_subtitle: 'Paramètres',
    permissions_account: 'Compte',
    permissions_active: 'actif',
    permissions_archived: 'archivé',
    permissions_roles: 'Rôle',
    permissions_manager: 'gestionnaire',
    permissions_employee: 'employé(e)',
    permissions_planning: 'employé(e) peut voir le planning',
    permissions_access: "Droits d'accès",
    permissions_ro: 'lecture seule',
    permissions_rw: 'lecture & écriture',
    error_report_title: 'Erreur',
    error_title: 'Zut, une erreur :-(',
    about_bc: 'WoTime',
    about_title: 'À propos de WoTime',
    about_subtitle:
      'Veuillez trouver ci-dessous tous les détails de la version actuelle de WoTime',
    about_version: 'Version',
    about_build_date: 'Construite au',
    about_support: 'Support',

    translations_title: 'Traductions',
    translations_subtitle: 'Liste de toutes les traductions de WoTime',
    translations_key: 'clé',
    translations_en: 'English',
    translations_fr: 'Français',
    translations_de: 'Deutsch',
    company_name_placeholder: 'Société',
    days: 'jour | journées',
    days_short: 'j',
    weekdays_1_short: 'Lun',
    weekdays_2_short: 'Mar',
    weekdays_3_short: 'Mer',
    weekdays_4_short: 'Jeu',
    weekdays_5_short: 'Ven',
    weekdays_6_short: 'Sam',
    weekdays_7_short: 'Sun',
    weekdays_1_long: 'Lundi',
    weekdays_2_long: 'Mardi',
    weekdays_3_long: 'Mercredi',
    weekdays_4_long: 'Jeudi',
    weekdays_5_long: 'Vendredi',
    weekdays_6_long: 'Samedi',
    weekdays_7_long: 'Dimanche',

    not_found_title: 'Introuvable',
    not_found_subtitle: 'La ressource ne peut pas être trouvée.',

    firebase_auth_email_already_in_use:
      "L'adresse email est déjà utilisée par un autre compte.",
    firebase_auth_invalid_email: "Cette adresse email n'est pas valide.",
    firebase_auth_operation_not_allowed:
      'Cette opération ne peut être effectuée',
    firebase_auth_weak_password:
      'Le mot de passe est trop faible et peut être deviné trop facilement',
    firebase_auth_user_disabled: 'Ce compte utilisateur est désactivé',
    firebase_auth_user_not_found: 'Utilisateur non trouvé',
    firebase_auth_wrong_password: 'Mot de passe incorrect',

    sqr_fire_upload_dnd_available:
      'Faites glisser et déposez les fichiers à télécharger',

    validation_email: "Cette adresse email n'est pas valide",
    validation_required: 'Ce champ est requis',

    partner_stsa_register_title: 'Membres de STSA',
    partner_stsa_register_subtitle:
      "Les membres de STSA bénéficient d'un tarif spécial de 6 CHF (au lieu de 9 CHF) / utilisateur / mois • 30 jours d'essai • 100 % satisfait ou remboursé",

    statuses_pending: 'En cours',
    statuses_pending_select: 'Dévérouiller',
    statuses_accepted: 'Accepté',
    statuses_accepted_select: 'Accepter',
    statuses_denied: 'Refuser',
    statuses_denied_select: 'Refusé',
    status_locked:
      "Si vous souhaitez modifier cet enregistrement, vous perdrez son statut de validation. Voulez-vous vraiment déverrouiller l'enregistrement ?",
    status_locked_event:
      "Si vous souhaitez modifier cet événement, vous perdrez son statut de validation. Voulez-vous vraiment déverrouiller l'événement?",
    status_unlocked: 'Dévérouiller',

    pagination_next: 'page suivante',
    pagination_previous: 'précédente',
    pagination_goto: 'page {page}'
  }
};
