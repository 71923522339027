<template>
  <div class="navbar-item has-dropdown is-hoverable" v-if="authenticated">
    <router-link class="navbar-link" :to="route(company)" v-if="company">
      <span class="icon"><fa :icon="['fal', 'database']" /></span>
      <span class="truncate" style="max-width: 10rem"
        ><company-name :company="company"
      /></span>
    </router-link>
    <a class="navbar-link is-arrowless" v-if="!company">
      <span class="icon"><fa :icon="['fal', 'database']" /></span>
      <span>{{ $t('navbar_companies') }}</span>
    </a>
    <div class="navbar-dropdown is-hidden-mobile">
      <router-link :to="{ name: 'companies' }" class="navbar-item">
        {{ $t('navbar_companies_mgmt') }}
      </router-link>
      <hr class="navbar-divider" />
      <div class="navbar-item uppercase">
        <span class="text-gray-600">
          {{ $t('nav_companies_recent') }}
        </span>
      </div>
      <router-link
        v-for="company in recents"
        :key="company.id"
        class="navbar-item"
        :to="route(company)"
      >
        <company-name :company="company" />
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { contains } from 'ramda';
import CompanyName from '@/components/CompanyName';
export default {
  name: 'NavCompanies',
  components: { CompanyName },
  computed: {
    ...mapGetters('auth', ['authenticated', 'uid']),
    ...mapState('company', { company: 'record', cid: 'id' }),
    ...mapState('companiesRecent', ['recents']),
  },
  methods: {
    route(company) {
      if (!company) {
        return { name: 'home' };
      } else if (contains(this.uid, company?.managerUids ?? [])) {
        return { name: 'company', params: { cid: company.id || this.cid } };
      } else if (contains(this.uid, company?.employeeUids ?? [])) {
        return {
          name: 'account',
          params: { cid: company.id || this.cid, aid: this.uid },
        };
      } else {
        return { name: 'home' };
      }
    },
  },
};
</script>
