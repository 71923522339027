<template>
  <div
    class="navbar-item has-dropdown is-hoverable is-hidden-mobile"
    v-if="authenticated"
  >
    <div class="navbar-link is-arrowless">
      <span class="truncate" style="max-width: 10rem">{{ displayName }}</span>
    </div>
    <div class="navbar-dropdown is-right">
      <sqr-router-link
        class="navbar-item"
        to-name="about"
        label="nav_user_about"
        is-link
      />
      <sqr-router-link
        class="navbar-item"
        to-name="password-change"
        label="nav_user_password_change"
        is-link
      />
      <sqr-router-link
        class="navbar-item"
        to-name="sign-out"
        label="navbar_sign_out"
        is-link
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SqrRouterLink from '@/sqrd/SqrRouterLink';
export default {
  name: 'NavUser',
  components: { SqrRouterLink },
  computed: {
    ...mapGetters('auth', ['authenticated', 'uid', 'displayName']),
  },
};
</script>
