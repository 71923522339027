import Vue from 'vue';
import stamp from '@/utils/stamp';

export default {
  namespaced: true,
  state: {
    removing: {},
    removeError: {},
  },
  mutations: {
    removing(state, { id }) {
      Vue.set(state.removing, id, true);
      Vue.delete(state.removeError, id);
    },
    removeError(state, { id, error }) {
      Vue.set(state.removeError, id, error);
      Vue.delete(state.removing, id);
    },
    removed(state, { id }) {
      Vue.delete(state.removing, id);
    },
  },
  actions: {
    async rm({ commit, rootGetters }, { id, path, doc }) {
      id = id ?? doc.id;
      path = path ?? doc.path;
      try {
        commit('removing', { id });
        const uid = rootGetters['auth/uid'];
        const ref = Vue.$db().doc(path);
        if (!doc?.updated?.uid !== uid) {
          await ref.update({ updated: stamp() });
        }
        await ref.delete();
      } catch (error) {
        return Promise.reject(error);
      }
    },
  },
};
