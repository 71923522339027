import { contains } from 'ramda';

export default {
  namespaced: true,
  state: {},
  getters: {
    isAdmin(state, getters, rootState) {
      const claims = rootState.auth?.claims ?? {};
      return claims.admin ?? false;
    },
    isManager(state, getters, rootState, rootGetters) {
      // const cid = rootState?.company?.id;
      // const claims = rootState.auth?.claims ?? {};
      // return contains(cid, claims.managerCids ?? []);
      const uid = rootGetters?.['auth/uid'];
      const company = rootState?.company?.record;
      return getters['isAdmin'] || contains(uid, company?.managerUids ?? []);
    },
    isEmployee(state, getters, rootState, rootGetters) {
      // const cid = rootState?.company?.id;
      // const claims = rootState.auth?.claims ?? {};
      // return contains(cid, claims.employeeCids ?? []);
      const uid = rootGetters?.['auth/uid'];
      const company = rootState?.company?.record;
      return contains(uid, company?.employeeUids ?? []);
    },
    viewPlanning(state, getters, rootState, rootGetters) {
      // const cid = rootState?.company?.id;
      // const claims = rootState.auth?.claims ?? {};
      // return contains(cid, claims.employeeCids ?? []);
      const uid = rootGetters?.['auth/uid'];
      const company = rootState?.company?.record;
      return contains(uid, company?.planningUids ?? []);
    },
    access(state, getters, rootState, rootGetters) {
      // const cid = rootState?.company?.id;
      // const claims = rootState.auth?.claims ?? {};
      // const found = contains(cid, claims.rwCids ?? []);
      const uid = rootGetters?.['auth/uid'];
      const company = rootState?.company?.record;
      const found = getters['isAdmin'] || contains(uid, company?.rwUids ?? []);
      return found ? 'rw' : 'ro';
    },
    isManagerRW(state, getters) {
      return getters.isManager && getters.access === 'rw';
    },
    isManagerRO(state, getters) {
      return getters.isManager && getters.access === 'ro';
    },
    isEmployeeRW(state, getters) {
      return getters.isEmployee && getters.access === 'rw';
    },
    isEmployeeRO(state, getters) {
      return getters.isEmployee && getters.access === 'ro';
    },
  },
};
