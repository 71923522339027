<template>
  <span>{{ name }}</span>
</template>

<script>
export default {
  name: 'CompanyName',
  props: { company: Object },
  computed: {
    name() {
      return this.company?.name ?? this.$t('company_name_placeholder');
    },
  },
};
</script>
