import Vue from 'vue';
import firebase from 'firebase/app';
const FieldValue = firebase.firestore.FieldValue;
import stamp from '@/utils/stamp';
import { intersection, difference } from 'ramda';

function getUpdates(status) {
  const updater = stamp();
  switch (status) {
    case 'accepted': {
      return {
        status,
        updated: updater,
        accepted: updater,
        denied: FieldValue.delete(),
      };
    }
    case 'denied': {
      return {
        status,
        updated: updater,
        accepted: FieldValue.delete(),
        denied: updater,
      };
    }
    default:
      return {
        status,
        updated: updater,
        accepted: FieldValue.delete(),
        denied: FieldValue.delete(),
      };
  }
}

export default {
  namespaced: true,
  state: {
    moderating: {},
    moderateError: {},
  },
  mutations: {
    moderating(state, { id }) {
      Vue.set(state.moderating, id, true);
      Vue.delete(state.moderateError, id);
    },
    modError(state, { id, error }) {
      Vue.set(state.moderateError, id, error);
    },
    moderated(state, { id }) {
      Vue.delete(state.moderating, id);
    },
  },
  actions: {
    async mod({ dispatch, commit }, { id, path, type, doc, status }) {
      // if status is already applied
      if (doc.status === status) return;
      id = id ?? doc.id;
      path = path ?? doc.path;
      try {
        commit('moderating', { id });
        const updates = getUpdates(status);
        const ref = Vue.$db().doc(path);
        await ref.update(updates);
        dispatch('email', { type, id, doc, status });
      } catch (error) {
        commit('modError', { id, error });
        return Promise.reject(error);
      } finally {
        commit('moderated', { id });
      }
    },
    async email({ rootGetters }, { id, type, doc, status }) {
      try {
        const cid = doc.companyId;
        const aid = doc.accountId;

        if (type !== 'event') return;
        const url = `${process.env.VUE_APP_URL}/c/${cid}/a/${aid}/e/${id}`;

        const company = rootGetters['company/record'];
        const companyName = company?.name;

        let toUids = [];
        if (status === 'pending') {
          toUids = intersection(company.managerUids, company.rwUids);
        } else if (status === 'accepted' || status === 'denied') {
          toUids = [doc.accountId];
        } else {
          return;
        }

        // we don't send to the current user
        const from = stamp();
        toUids = difference(toUids, [from.uid]);

        if (toUids.length === 0) {
          return;
        }

        await Vue.$db()
          .collection('emails')
          .add({
            from: '◼️ SQRD WoTime <info@wotime.ch>',
            // to: 'guillaume@sqrd.com',
            toUids,
            template: {
              name: [type, status, 'en'].join('-'),
              data: {
                from,
                companyName,
                [type]: doc,
                url,
              },
            },
          });
      } catch (error) {
        return Promise.reject(error);
      }
    },
  },
};
