import Vue from 'vue';
import browserLanguage from 'in-browser-language';

export default {
  namespaced: true,
  state: {
    locale: '',
  },
  getters: {
    locales() {
      return ['en', 'de', 'fr'];
    },
  },
  mutations: {
    localSet(state, { locale }) {
      state.locale = locale;
      Vue.$locale.locale = locale;
      Vue.$fb().auth().languageCode = locale;
    },
  },
  actions: {
    async localeChange({ commit, rootGetters }, { locale }) {
      commit({ type: 'localSet', locale });
      const authenticated = rootGetters['auth/authenticated'];
      const uid = rootGetters['auth/uid'];
      if (authenticated && uid) {
        try {
          await Vue.$db().collection('users').doc(uid).update({ locale });
        } catch (error) {
          // console.log(error);
          return Promise.reject(error);
        }
      }
    },
    init: {
      root: true,
      handler({ state, getters, commit, dispatch }) {
        if (!state.locale) {
          const locale = browserLanguage.pick(getters.locales, 'en');
          dispatch({ type: 'localeChange', locale });
        } else {
          commit({ type: 'localSet', locale: state.locale });
        }
      },
    },
  },
};
