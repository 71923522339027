import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/views/Home.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    meta: { public: true },
    component: Home,
  },
  {
    path: '/about',
    name: 'about',
    meta: {},
    component: () =>
      import(/* webpackChunkName: "about" */ '@/views/About.vue'),
  },
  {
    path: '/sign-up',
    name: 'sign-up',
    meta: { public: true },
    component: () =>
      import(/* webpackChunkName: "sign-up" */ '@/views/SignUp.vue'),
  },
  {
    path: '/start/user',
    name: 'start-user',
    meta: {},
    component: () =>
      import(/* webpackChunkName: "start" */ '@/views/StartUser.vue'),
  },
  {
    path: '/start/company',
    name: 'start-company',
    meta: {},
    component: () =>
      import(/* webpackChunkName: "start" */ '@/views/StartCompany.vue'),
  },
  {
    path: '/sign-in',
    name: 'sign-in',
    meta: { public: true },
    component: () =>
      import(/* webpackChunkName: "sign-in" */ '@/views/SignIn.vue'),
  },
  {
    path: '/password/lost',
    name: 'password-lost',
    meta: { public: true },
    component: () =>
      import(
        /* webpackChunkName: "password-lost" */ '@/views/SignPasswordLost.vue'
      ),
  },
  {
    path: '/lost-password',
    meta: { public: true },
    redirect: { name: 'password-lost' },
  },
  {
    path: '/password/change',
    name: 'password-change',
    meta: { public: true },
    component: () =>
      import(
        /* webpackChunkName: "password-lost" */ '@/views/PasswordChange.vue'
      ),
  },
  {
    path: '/sign-out',
    name: 'sign-out',
    meta: { public: true },
    component: () =>
      import(/* webpackChunkName: "sign-out" */ '@/views/SignOut.vue'),
  },
  {
    path: '/c',
    name: 'companies',
    meta: { role: 'manager' },
    props: true,
    component: () =>
      import(/* webpackChunkName: "companies" */ '@/views/Companies.vue'),
  },
  {
    path: '/c/:cid',
    name: 'company',
    meta: { role: 'manager' },
    props: true,
    component: () =>
      import(/* webpackChunkName: "company" */ '@/views/Company.vue'),
  },
  {
    path: '/c/:cid/o',
    name: 'orgs',
    meta: { role: 'manager' },
    props: true,
    component: () => import(/* webpackChunkName: "orgs" */ '@/views/Orgs.vue'),
  },
  {
    path: '/c/:cid/o/:oid',
    name: 'org',
    meta: { role: 'manager' },
    props: true,
    component: () => import(/* webpackChunkName: "org" */ '@/views/Org.vue'),
  },
  {
    path: '/c/:cid/p',
    name: 'planning',
    props: true,
    meta: { role: 'manager' },
    component: () =>
      import(/* webpackChunkName: "planning" */ '@/views/Planning.vue'),
  },
  {
    path: '/c/:cid/r',
    name: 'company-requests',
    meta: { role: 'manager' },
    props: true,
    component: () =>
      import(/* webpackChunkName: "requests" */ '@/views/CompanyRequests.vue'),
  },
  {
    path: '/c/:cid/holidays',
    name: 'company-holidays',
    meta: { role: 'manager' },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "company-holidays" */ '@/views/CompanyHolidays.vue'
      ),
  },
  {
    path: '/c/:cid/vr',
    name: 'company-rights',
    meta: { role: 'manager' },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "company-rights" */ '@/views/CompanyRights.vue'
      ),
  },
  {
    path: '/c/:cid/s/name',
    name: 'company-name',
    meta: { role: 'manager' },
    props: true,
    component: () =>
      import(/* webpackChunkName: "company-name" */ '@/views/CompanyName.vue'),
  },
  {
    path: '/c/:cid/s/defaults',
    name: 'company-defaults',
    meta: { role: 'manager' },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "company-defaults" */ '@/views/CompanyDefaults.vue'
      ),
  },
  {
    path: '/c/:cid/s/models',
    name: 'company-models',
    meta: { role: 'manager' },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "company-models" */ '@/views/CompanyModels.vue'
      ),
  },
  {
    path: '/c/:cid/s/extensions',
    name: 'company-extensions',
    meta: { role: 'manager' },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "company-extensions" */ '@/views/CompanyExtensions.vue'
      ),
  },
  {
    path: '/c/:cid/s/integrations',
    name: 'company-integrations',
    meta: { role: 'manager' },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "company-integrations" */ '@/views/CompanyIntegrations.vue'
      ),
  },
  {
    path: '/c/:cid/s/integrations/:iid/centaur',
    name: 'company-integration-centaur',
    meta: { role: 'manager' },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "company-integration-centaur" */ '@/views/CompanyIntegrationCentaur.vue'
      ),
  },
  {
    path: '/c/:cid/r/work',
    name: 'company-report-work',
    meta: { role: 'manager' },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "company-report-work" */ '@/views/CompanyReportWork.vue'
      ),
  },
  {
    path: '/c/:cid/r/vacations',
    name: 'company-report-vacations',
    meta: { role: 'manager' },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "company-report-vacations" */ '@/views/CompanyReportVacations.vue'
      ),
  },
  {
    path: '/c/:cid/r/passages',
    name: 'company-report-passages',
    meta: { role: 'manager' },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "company" */ '@/views/CompanyReportPassages.vue'
      ),
  },
  {
    path: '/c/:cid/r/trail',
    name: 'company-audit-trail',
    meta: { role: 'manager' },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "company-audit-trail" */ '@/views/CompanyAuditTrail.vue'
      ),
  },
  {
    path: '/c/:cid/a',
    name: 'accounts',
    meta: { role: 'manager' },
    props: true,
    component: () =>
      import(/* webpackChunkName: "accounts" */ '@/views/Accounts.vue'),
  },
  {
    path: '/c/:cid/ao/:oid',
    name: 'accounts-org',
    meta: { role: 'manager' },
    props: true,
    component: () =>
      import(/* webpackChunkName: "accounts" */ '@/views/AccountsOrg.vue'),
  },
  {
    path: '/c/:cid/a/add',
    name: 'account-add',
    meta: { role: 'manager' },
    props: true,
    component: () =>
      import(/* webpackChunkName: "accounts" */ '@/views/AccountAdd.vue'),
  },
  {
    path: '/c/:cid/a/:aid',
    name: 'account',
    meta: { role: 'employee' },
    props: true,
    component: () =>
      import(/* webpackChunkName: "account" */ '@/views/Account.vue'),
  },
  {
    path: '/c/:cid/a/:aid/s',
    name: 'sheets',
    meta: { role: 'employee' },
    props: true,
    component: () =>
      import(/* webpackChunkName: "sheets" */ '@/views/Sheets.vue'),
  },
  {
    path: '/c/:cid/a/:aid/s/y/:year',
    name: 'sheets-year',
    meta: { role: 'employee' },
    props: true,
    component: () =>
      import(/* webpackChunkName: "sheets" */ '@/views/Sheets.vue'),
  },
  {
    path: '/c/:cid/a/:aid/s/:sid',
    name: 'sheet',
    meta: { role: 'employee' },
    props: true,
    component: () =>
      import(/* webpackChunkName: "sheet" */ '@/views/Sheet.vue'),
  },
  {
    path: '/c/:cid/a/:aid/s/:sid/versions',
    name: 'sheet-versions',
    meta: { role: 'manager' },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "sheet-versions" */ '@/views/SheetVersions.vue'
      ),
  },
  {
    path: '/c/:cid/a/:aid/e',
    name: 'events',
    meta: { role: 'employee' },
    props: true,
    component: () =>
      import(/* webpackChunkName: "events" */ '@/views/Events.vue'),
  },
  {
    path: '/c/:cid/a/:aid/e/add',
    name: 'event-add',
    meta: { role: 'employee' },
    props: true,
    component: () =>
      import(/* webpackChunkName: "event" */ '@/views/Event.vue'),
  },
  {
    path: '/c/:cid/a/:aid/e/:eid',
    name: 'event',
    meta: { role: 'employee' },
    props: true,
    component: () =>
      import(/* webpackChunkName: "event" */ '@/views/Event.vue'),
  },
  {
    path: '/c/:cid/a/:aid/vr',
    name: 'rights',
    meta: { role: 'employee' },
    props: true,
    component: () =>
      import(/* webpackChunkName: "rights" */ '@/views/Rights.vue'),
  },
  {
    path: '/c/:cid/a/:aid/vr/add',
    name: 'right-add',
    meta: { role: 'employee' },
    props: true,
    component: () =>
      import(/* webpackChunkName: "right-add" */ '@/views/Right.vue'),
  },
  {
    path: '/c/:cid/a/:aid/vr/:rid',
    name: 'right',
    meta: { role: 'employee' },
    props: true,
    component: () =>
      import(/* webpackChunkName: "right" */ '@/views/Right.vue'),
  },
  // {
  //   path: '/c/:cid/a/:aid/p',
  //   name: 'passages',
  //   meta: { role: 'employee' },
  //   props: true,
  //   component: () => import(/* webpackChunkName: "passages" */ '@/views/Passages.vue')
  // },
  // {
  //   path: '/c/:cid/a/:aid/p/:pid',
  //   name: 'passages',
  //   meta: { role: 'employee' },
  //   props: true,
  //   component: () => import(/* webpackChunkName: "passage" */ '@/views/Passage.vue')
  // },
  {
    path: '/c/:cid/a/:aid/r/w',
    name: 'report-work',
    meta: { role: 'employee' },
    props: true,
    component: () =>
      import(/* webpackChunkName: "report-work" */ '@/views/ReportWork.vue'),
  },
  {
    path: '/c/:cid/a/:aid/r/v',
    name: 'report-vacations',
    meta: { role: 'employee' },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "report-vacations" */ '@/views/ReportVacations.vue'
      ),
  },
  {
    path: '/c/:cid/a/:aid/r/p',
    name: 'report-passages',
    meta: { role: 'employee' },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "report-passages" */ '@/views/ReportPassages.vue'
      ),
  },
  {
    path: '/c/:cid/a/:aid/settings',
    name: 'settings',
    meta: { role: 'employee' },
    props: true,
    component: () =>
      import(/* webpackChunkName: "settings" */ '@/views/Settings.vue'),
  },
  {
    path: '/c/:cid/a/:aid/perms',
    name: 'permissions',
    meta: { role: 'manager' },
    props: true,
    component: () =>
      import(/* webpackChunkName: "permissions" */ '@/views/Permissions.vue'),
  },
  {
    path: '/c/:cid/a/:aid/email',
    name: 'email',
    meta: { role: 'admin' },
    props: true,
    component: () =>
      import(/* webpackChunkName: "email" */ '@/views/Email.vue'),
  },
  {
    path: '/translations',
    name: 'translations',
    meta: { role: 'admin' },
    component: () =>
      import(/* webpackChunkName: "translations" */ '@/views/Translations.vue'),
  },
  {
    path: '/auth',
    name: 'auth',
    meta: { public: true },
    component: () => import(/* webpackChunkName: "auth" */ '@/views/Auth.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  // linkActiveClass: 'is-active',
  linkExactActiveClass: 'is-active',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

async function checkAccess(to, next) {
  const meta = to?.meta;

  // if the destination is public just move one
  if (!meta || (meta && meta.public)) {
    return next();
  }

  // if the user is authenticated, we move on
  if (Vue.$store.getters['auth/authenticated']) {
    return next();
  }

  let unsub;
  const prom = new Promise((resolve, reject) => {
    unsub = Vue.$fb().auth().onAuthStateChanged(resolve, reject);
  });
  const user = await prom;
  // we have to wait on the user
  if (user) {
    next();
  } else {
    next({ name: 'sign-in', query: { redirect: to.path } });
  }

  if (unsub) unsub();
}

router.beforeEach(async (to, from, next) => {
  const name = to?.name;
  const fromName = from?.name;
  Vue.$fa()?.logEvent('routeView', { name, fromName });
  await checkAccess(to, next);
});

export default router;
