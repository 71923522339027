export default function ({ limit }) {
  limit = limit ?? 5;
  return {
    namespaced: true,
    state: {
      recents: [],
    },
    mutations: {
      push(state, { id, item }) {
        id = id ?? item.id;
        const recents = state.recents.filter(item => item.id !== id);
        state.recents = [{ ...item, id }, ...recents].slice(0, limit);
      },
      clear(state) {
        state.recents = [];
      },
    },
  };
}
