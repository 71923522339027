import Vue from 'vue';

export default function () {
  let unsub;
  return {
    namespaced: true,
    state: {
      path: '',
      exists: false,
      doc: null,
      loading: false,
      loadError: null,
      sending: false,
      sendError: null,
    },
    getters: {},
    mutations: {
      loading(state, { path }) {
        state.path = path;
        state.loading = true;
        state.loadError = null;
      },
      loaded(state) {
        state.loading = false;
      },
      doc(state, { doc, exists }) {
        state.exists = exists;
        state.doc = doc;
      },
      loadError(state, { error }) {
        state.error = error;
      },
      sending(state) {
        state.sending = true;
        state.sendError = null;
      },
      sent(state) {
        state.sending = false;
      },
      sendError(state, { error }) {
        state.sendError = error;
      },
      reset(state) {
        state.path = '';
        state.exists = false;
        state.doc = null;
        state.loading = false;
        state.loadError = null;
        state.sending = false;
        state.sendError = null;
      },
    },
    actions: {
      async sub({ commit }, { path, init }) {
        commit('loading', { path });
        unsub = Vue.$db()
          .doc(path)
          .onSnapshot(
            snap => {
              const exists = snap.exists;
              const doc = exists
                ? { ...snap.data(), path, id: snap.id }
                : init || null;
              commit('doc', { exists, doc });
              commit('loaded');
            },
            error => {
              commit('loadError', { error });
            }
          );
      },
      async unsub() {
        if (unsub) unsub();
      },
      // async update({}, path, doc, merge) {},
      // we cannot use set (available on Vue), so we use send
      async send({ state, commit }, { path, doc, merge }) {
        try {
          commit('sending');
          await Vue.$db()
            .doc(path || state.path)
            .set(doc, { merge });
        } catch (error) {
          commit('sendError', { error });
          return Promise.reject(error);
        } finally {
          commit('sent');
        }
      },
    },
  };
}
