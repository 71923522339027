import { mapState } from 'vuex';
import { contains } from 'ramda';
export default {
  computed: {
    ...mapState('company', { cid: 'id', company: 'record' }),
    ...mapState('account', { aid: 'id', account: 'record' }),
    isEmployee() {
      return contains(this.aid, this.company?.employeeUids ?? []);
    },
  },
};
