<template>
  <div class="navbar-item has-dropdown is-hoverable is-hidden-mobile">
    <div class="navbar-link is-arrowless">
      <span class="icon"><fa :icon="['fal', 'globe']" /></span>
      <span class="uppercase">{{ locale }}</span>
    </div>
    <div class="navbar-dropdown is-hidden-mobile">
      <a
        class="navbar-item"
        v-for="locale in locales"
        :key="locale"
        @click="localeChange({ locale })"
      >
        {{ $t('translations_' + locale) }}
      </a>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
export default {
  name: 'NavLocales',
  computed: {
    ...mapState('locale', ['locale']),
    ...mapGetters('locale', ['locales']),
  },
  methods: {
    ...mapActions('locale', ['localeChange']),
  },
};
</script>
