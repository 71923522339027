import Vue from 'vue';
import { assocPath, dissocPath, equals, isNil } from 'ramda'; // reject
import firebase from 'firebase/app';
const FieldValue = firebase.firestore.FieldValue;
import buildRef from '@/utils/buildRef';
import stamp from '@/utils/stamp';

export default function ({ timeoutMs }) {
  let unsubscribe = null;
  timeoutMs = timeoutMs ?? 0;
  let timeoutFn = null;
  return {
    namespaced: true,
    state: {
      path: [],
      id: null,
      record: null,
      exists: false,

      updates: {},
      previous: {},

      loading: false,
      loadError: null,

      saving: false,
      saveError: null,

      removing: false,
      removeError: null,
    },
    getters: {
      id: state => state.id,
      record: state => state.record,
      updates: state => state.updates,

      loading: state => state.loading,
      loadError: state => state.loadError,

      sending: state => state.sending,
      sendError: state => state.sendError,

      saving: state => state.saving,
      saveError: state => state.saveError,

      error: state => state.loadError || state.sendError || state.saveError,
    },
    mutations: {
      init(state, { id, path, record }) {
        state.path = path || [];
        state.id = id;
        state.exists = false;
        state.record = record;
        state.updates = {};
        state.previous = {};
        state.loadError = null;
        state.loading = false;
        state.sending = false;
        state.sendError = null;
        state.saving = false;
        state.saveError = null;
        state.removing = false;
        state.removeError = null;
      },
      storeSet(state, { field, value }) {
        Vue.set(state, field, value);
      },
      loading(state, { path, id }) {
        state.path = path || [];
        state.id = id;
        state.loadError = null;
        state.loading = true;
        state.updates = {};
        state.previous = {};
        state.sending = false;
        state.sendError = null;
        if (timeoutFn) {
          // eslint-disable-next-line no-console
          console.log('something wrong, a timeout is still recorded');
        }
      },
      loaded(state, { path, id, doc, exists }) {
        state.path = path || [];
        state.id = id;
        state.record = doc;
        state.exists = exists;
        // NOTE we keep the updates to avoid racing conditions
        // state.updates = {};
        state.loadError = null;
        state.loading = false;
        state.sending = false;
        // state.sendError = null;
      },
      fieldSet(state, { path, field, value }) {
        if (!state.record) return;

        // cleanup value
        const valueUpdate = isNil(value) ? FieldValue.delete() : value;

        if (path) {
          const full = field ? [...path, field] : path;
          if (isNil(value)) {
            Vue.set(state, 'record', dissocPath(full, state.record));
          } else {
            Vue.set(state, 'record', assocPath(full, value, state.record));
          }
          state.updates[full.join('.')] = valueUpdate;
        } else {
          if (isNil(value)) {
            Vue.delete(state.record, field);
          } else {
            Vue.set(state.record, field, value);
          }
          Vue.set(state.updates, field, valueUpdate);
        }
      },
      loadError(state, { error }) {
        state.loading = false;
        state.loadError = { error };
      },
      sending(state) {
        state.sending = true;
        state.sendError = null;
      },
      sent(state, { updates }) {
        state.sending = false;
        state.previous = updates;
      },
      sendError(state, { error }) {
        state.sendError = error;
        state.sending = false;
      },
      saving(state) {
        state.saving = true;
        state.saveError = null;
      },
      saved(state, { id }) {
        state.id = id;
        state.exists = true;
        state.saveError = null;
        state.saving = false;
      },
      saveError(state, { error }) {
        state.saveError = error;
        state.saving = false;
      },
      removing(state) {
        state.removing = true;
        state.removeError = null;
      },
      removeError(state, { error }) {
        state.removeError = error;
        state.removing = false;
      },
      removed(state) {
        state.removing = false;
      },
      reset(state) {
        state.path = [];
        state.exists = false;
        state.id = null;
        state.record = null;
        state.updates = {};
        state.previous = {};
        state.loading = false;
        state.loadError = null;
        state.saving = false;
        state.saveError = null;
        state.removing = false;
        state.removeError = null;
      },
    },
    actions: {
      init({ commit }, { path, id, record }) {
        commit('init', { path, id, record });
      },
      sub(context, { path, id }) {
        if (!id) return;
        if (timeoutFn) {
          // eslint-disable-next-line no-console
          console.log('something wrong, a timeout is still recorded');
          // we should wait for the end of this timeout
        }
        context.commit('loading', { path, id });
        return new Promise((resolve, reject) => {
          const ref = buildRef([...path, id]);
          unsubscribe = ref.onSnapshot(
            docSnapshot => {
              const exists = docSnapshot.exists;
              const doc = exists ? docSnapshot.data() : null;
              context.commit('loaded', { path, id, doc, exists });
              resolve(doc);
            },
            error => {
              context.commit('loadError', { error });
              reject(error);
            }
          );
        });
      },
      fieldSet({ commit, dispatch }, { path, field, value }) {
        if (timeoutMs > 0) {
          // store the change on field
          commit('fieldSet', { path, field, value });
          // if a time out is renning, clear it
          if (timeoutFn) {
            clearTimeout(timeoutFn);
          }
          // create a new time out
          timeoutFn = setTimeout(() => {
            dispatch('send', { clear: false });
          }, timeoutMs);
        } else {
          commit('fieldSet', { path, field, value });
          return dispatch('send');
        }
      },
      async save({ state, commit }) {
        try {
          commit('saving');
          const record = {
            ...state.record,
            updated: stamp(),
          };
          let id = state.id;
          if (!id) {
            const path = [...state.path];
            const ref = buildRef(path);
            const res = await ref.add(record);
            id = res.id;
            // NOTE should we subscribe to futur changes?
          } else {
            const path = [...state.path, state.id];
            const ref = buildRef(path);
            await ref.set(record);
          }
          commit('saved', { id });
          return { id };
        } catch (error) {
          commit('saveError', { error });
          return Promise.reject(error);
        }
      },
      async update({ state, commit }, payload) {
        try {
          const id = state.id;
          if (!id) return;
          await buildRef([...state.path, state.id]).update(payload);
        } catch (error) {
          commit('saveError', { error });
          return Promise.reject(error);
        }
      },
      async send({ state, commit }, { clear }) {
        try {
          if (clear && timeoutFn) {
            clearTimeout(timeoutFn);
          }
          commit('sending');
          const path = [...state.path, state.id];
          if (Object.keys(state.updates).length === 0) {
            return;
          }
          if (equals(state.updates, state.previous)) {
            return;
          }
          const ref = buildRef(path);
          const updates = JSON.parse(JSON.stringify(state.updates));
          const payload = {
            ...state.updates,
            updated: stamp(),
          };
          const res = await ref.update(payload);
          commit('sent', { updates });
          return res;
        } catch (error) {
          commit('sendError', { error });
        }
      },
      async remove({ state, commit }) {
        if (!state.id) return;
        try {
          commit('removing');
          const path = [...state.path, state.id];
          const ref = buildRef(path);
          await ref.delete();
          commit('removed');
        } catch (error) {
          commit('removeError', { error });
        }
      },
      unsub({ commit }, payload) {
        if (unsubscribe) {
          unsubscribe();
        }
        if (payload.reset) {
          commit('reset');
        }
      },
    },
  };
}
