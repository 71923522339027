import Vue from 'vue';
import buildRef from '@/utils/buildRef';

export default function () {
  let unsubscribe = {};
  return {
    namespaced: true,
    state: {
      pathes: {},
      loading: {},
      loadError: {},
      exists: {},
      docs: {},
    },
    getters: {},
    mutations: {
      loading(state, { key, path }) {
        Vue.set(state.pathes, key, path);
        Vue.set(state.loading, key, true);
        Vue.delete(state.loadError, key);
      },
      loaded(state, { key, exists, doc }) {
        Vue.set(state.exists, key, exists);
        if (doc) {
          Vue.set(state.docs, key, doc);
        } else {
          Vue.delete(state.docs, key);
        }
        Vue.delete(state.loading, key);
      },
      docChange(state, { key, type, id, doc, newIndex, oldIndex }) {
        if (!state.docs[key]) {
          Vue.set(state.docs, key, []);
        }
        let docs = state.docs[key];
        doc = { ...doc, id };
        if (type === 'added') {
          docs.splice(newIndex, 0, doc);
          // if we want to handle references we would do it here
        } else if (type === 'modified') {
          // remove the old one first
          docs.splice(oldIndex, 1);
          // if we want to handle references we would have to unsubscribe
          // from old references' listeners and subscribe to the new ones
          docs.splice(newIndex, 0, doc);
        } else if (type === 'removed') {
          docs.splice(oldIndex, 1);
          // if we want to handle references we need to unsubscribe
          // from old references
        }
      },
      loadError(state, { key, error }) {
        Vue.set(state.loadError, key, error);
        Vue.delete(state.loading, key);
      },
      reset(state, { key }) {
        Vue.delete(state.pathes, key);
        Vue.delete(state.loading, key);
        Vue.delete(state.docs, key);
        Vue.delete(state.loadError, key);
      },
    },
    actions: {
      sub({ commit, dispatch }, { pathes, where }) {
        dispatch('unsub');
        if (typeof pathes !== 'object') {
          throw new Error('pathes must be an object');
        }
        // console.log(pathes);
        const entries = Object.entries(pathes);
        entries.map(([key, path]) => {
          commit('loading', { key, path });
          const collec = buildRef(path);
          const query = where.reduce((query, w) => query.where(...w), collec);
          unsubscribe[key] = query.onSnapshot(
            snap => {
              // const exists = snap.exists;
              // const doc = exists ? snap.data() : null;
              // // here
              // commit('loaded', { key, exists, doc });

              snap.docChanges().forEach(change => {
                commit('docChange', {
                  key,
                  type: change.type,
                  id: change.doc.id,
                  doc: change.doc.data(),
                  newIndex: change.newIndex,
                  oldIndex: change.oldIndex,
                });
              });
            },
            error => commit('loadError', { key, error })
          );
        });
      },
      unsub({ commit }) {
        const entries = Object.entries(unsubscribe);
        entries.forEach(([key, unsubFn]) => {
          unsubFn();
          commit('reset', { key });
        });
        unsubscribe = {};
      },
    },
  };
}
