<template>
  <div>
    <label class="label" v-if="labelTr">{{ labelTr }}</label>
    <progress
      class="progress is-small is-primary is-radiusless is-bold"
      v-bind="$attrs"
    />
  </div>
</template>

<script>
import label from './mixins/label';
export default {
  mixins: [label],
  name: 'SqrProgress',
};
</script>
