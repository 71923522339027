export default {
  locale: 'de',
  messages: {
    update_banner_title: 'WoTime Update',
    update_banner_loading: 'Update wird geladen',
    update_banner_ready: 'Das WoTime-Update ist fertig',
    update_banner_updating: 'WoTime wird aktualisiert',
    update_banner_action: 'Jetzt aktualisieren',

    route_loading: 'Wird geladen…',
    route_home: 'Zuhause',
    route_launch: 'Lass uns anfangen',
    route_start: 'Einige Fragen',
    route_login: 'Einloggen',
    route_password_change: 'Kennwort Change Request',
    route_password_reset: 'Kennwort Set',
    route_register: 'Registrieren',
    route_accounts: 'Konten',
    route_database_dump: 'Datenbank-Backup',
    route_company: 'Firmeneinstellungen',
    route_settings: 'Einstellung',
    route_contract: 'Vertrag',
    route_planning: 'Planung',
    route_planning_holidays: 'Set Urlaub',
    route_requests: 'Kalender Event Anfragen',
    route_organisation: 'Planungsorganisation',
    route_company_rights: 'Urlaub Rechte',
    route_company_report_sheets: 'Firma Blätter Bericht',
    route_company_report_vacations: 'Firma Urlaubsbericht',
    route_company_report_passages: 'Firma Passages Bericht',
    route_subscription: 'Abonnement',
    route_users: 'Benutzer',
    route_user_add: 'Fügen Sie einen Benutzer',
    route_user_edit: 'Benutzer-Update',
    route_user: 'Benutzer',
    route_sheets: 'Wochen',
    route_sheet: 'Arbeitswoche',
    route_passages: 'Passages',
    route_events: 'Kalender Ereignisse',
    route_event_add: 'Eine Veranstaltung hinzufügen',
    route_events_holidays: 'Ferien',
    route_event_edit: 'Event-Update',
    route_rights: 'Urlaubsrechte',
    route_right_add: 'Hinzufügen eines Rechts',
    route_right_edit: 'rechts-Update',
    route_report_sheets: 'Arbeitswochen Bericht',
    route_report_vacations: 'Urlaubsbericht',
    route_report_passages: 'Passages',
    route_about: 'Über',
    route_logout: 'Ausloggen',
    route_not_found: 'Funktion nicht gefunden',
    navbar_companies: 'Firmen',
    navbar_companies_mgmt: 'Firmen ansehen',
    navbar_company_management: 'Verwaltung',
    navbar_company_planning: 'Planung',
    navbar_company_requests: 'Anfragen',
    navbar_company_rights: 'Urlaub Rechte',
    navbar_company_report_sheets: 'Arbeitsbericht',
    navbar_company_report_vacations: 'Urlaubsbericht',
    navbar_company_report_passages: 'Passages Bericht',
    navbar_company_subscription: 'Abonnement',
    navbar_company_settings: 'Firmeneinstellungen',
    navbar_company_accounts: 'Konten',
    navbar_company_accounts_mgmt: 'Konten verwalten',
    navbar_company_accounts_recently: 'Zuletzt besuchte',
    navbar_users: 'Benutzerverwaltung',
    navbar_account_sheets: 'Arbeitswochen',
    navbar_vacation_rights: 'Urlaub Rechte',
    navbar_passages: 'Passages',
    navbar_account_calendar: 'Kalender Ereignisse',
    navbar_rights: 'Urlaub Rechte',
    navbar_account_reports: 'Berichte',
    navbar_report_work: 'Arbeitsbericht',
    navbar_report_vacations: 'Urlaubsbericht',
    navbar_report_passages: 'Passages Bericht',
    navbar_settings: 'Einstellungen',
    navbar_account: 'Account Einstellungen',
    navbar_sign_in: 'Einloggen',
    navbar_sign_up: 'Anmelden',
    navbar_sign_out: 'Ausloggen',
    nav_companies_recent: 'Kürzlich',
    nav_user_password_change: 'Passwort ändern',
    nav_user_about: 'Über WoTime',
    breadcrumb_home: 'WoTime',
    breadcrumb_companies: 'Firmen',
    breadcrumb_company_planning: 'Planung',
    breadcrumb_company_settings: 'Einstellungen',
    breadcrumb_company_name: 'Name',
    breadcrumb_company_defaults: 'neues Konto Standardwerte',
    breadcrumb_company_models: 'Zeiteinträge Modelle',
    breadcrumb_company_extensions: 'Erweiterungen',
    breadcrumb_company_holidays: 'Ferien',
    breadcrumb_company_requests: 'Anfragen',
    breadcrumb_company_rights: 'Urlaub Rechte',
    breadcrumb_company_report_work: 'Firma Arbeitsbericht',
    breadcrumb_company_report_vacations: 'Betriebsferien Bericht',
    breadcrumb_company_report_passages: 'Firma Passagen Bericht',
    breadcrumb_orgs: 'Organisationen',
    breadcrumb_accounts: 'Konten',
    breadcrumb_account_add: 'hinzufügen',
    breadcrumb_account_sheets: 'Arbeitswochen',
    breadcrumb_events: 'Kalenderereignisse',
    breadcrumb_rights: 'Urlaubsrechte',
    breadcrumb_reports: 'Berichte',
    breadcrumb_report_sheets: 'Arbeit',
    breadcrumb_report_vacations: 'Urlaube',
    breadcrumb_settings: 'Einstellungen',
    breadcrumb_permissions: 'Rollen & Zugriffsrechte',
    buttons_back: 'Zurück',
    buttons_cancel: 'Stornieren',
    buttons_save: 'Speichern',
    buttons_continue: 'Weiter',
    buttons_remove: 'Entfernen',
    buttons_load: 'Laden',
    progress_loading: 'Wird geladen…',
    progress_saving: 'Sparen…',
    home_sign_in: 'Melden Sie sich in WoTime',
    home_sign_up: 'Ein neues Konto eröffnen',
    company_card_management: 'Verwaltung',
    company_card_sheets: 'Arbeitswochen',
    company_card_account: 'Konto',

    companies_start: 'Firma hinzufügen',

    company_management_planning: 'Firmaplanung',
    company_planning: 'Planung',
    company_planning_help:
      'Sehen Sie alle Kalenderereignisse für alle Mitarbeiter',
    company_requests: 'Anfragen',
    company_requests_help:
      'Akzeptieren / Ablehnen alle Kalenderereignisse von den Mitarbeitern vorgeschlagen',
    company_holidays: 'Ferien',
    company_holidays_help: 'In feiertags Planung von Kanton',
    company_rights: 'Urlaubsrechte',
    company_rights_help:
      'Aktualisieren Sie alle Mitarbeiter Urlaubsrechte auf einmal alle',
    company_requests_none: 'Es gibt keine zukünftige Ereignisanfrage.',
    notify_event_past_title: 'Ereignis aus der Vergangenheit',
    notify_event_past_message:
      'Sie sind ein Kalenderereignis in der Vergangenheit aktualisiert wird, haben die passenden Arbeitswochen bereits erstellt. Bitte aktualisieren Sie die Blatteinträge.',
    start_company_title: 'Starten Sie WoTime mit',
    start_company_subtitle:
      'Begleiten Sie Ihr Firmenkonto, indem Sie sich an oder eine neue Firma erstellen',
    // start_company_mode: 'Wie werden Sie WoTime verwenden?',
    // start_company_mode_company: 'als Firma',
    // start_company_mode_company_help:
    //   'mehrere Mitarbeiter ihre Zeit auf WoTime berichten',
    // start_company_mode_personal: 'als Einzel',
    // start_company_mode_personal_help:
    //   'Ich werde die einzige Person Tracking meiner Zeit',
    // start_company_company_name: 'Ihr Firmenname?',
    // start_company_personal_title: 'Lass uns anfangen',
    // start_company_personal_subtitle:
    //   'Um Ihre Arbeitszeit und Urlaub zu verfolgen brauchen wir ein paar Antworten',
    // start_company_personal_email: 'deine Emailadresse',
    // start_company_personal_email_help: 'für die Datenrettung Zweck',
    // start_company_personal_vacations:
    //   'Wie viele Tage Urlaub haben Sie für dieses Jahr noch?',
    // start_company_personal_work_time_due: 'Wie viele Stunden Sie pro Woche arbeiten?',
    // start_company_personal_conversion: 'Wie viele Stunden am Tag der Arbeit wert ist?',
    // start_company_personal_confirm: 'Beginnen Sie mit WoTime!',

    start_company_work_model: 'Ich sollte',
    start_company_work_model_42:
      'von Montag bis Freitag 42 Stunden pro Woche arbeiten',
    start_company_work_model_41:
      'von Montag bis Freitag 41 Stunden pro Woche arbeiten',
    start_company_work_model_40:
      'von Montag bis Freitag 40 Stunden pro Woche arbeiten',
    start_company_work_model_other: 'andere…',
    start_company_weekdays_worked: 'Wochentage gearbeitet',
    start_company_week_due:
      'Wie viele Arbeitsstunden sind vertraglich pro Woche fällig?',
    start_company_week_due_placeholder: 'ss:mm',
    start_company_week_due_help: 'Normalerweise 40:00 oder 41:00 oder 42:00',
    start_company_week_conv: 'Wie viele Stunden pro Tag Arbeit ist es wert?',
    start_company_week_conv_help:
      'Wenn Ihre Arbeit 40:00 Stunden pro Woche (5 Tage) pro Tag 08:00 Stunden wert ist. <br> Wenn Ihre Arbeit 41:00 Stunden pro Woche (5 Tage) pro Tag 08:12 Stunden wert ist. <br> Wenn Ihre Arbeit 42:00 Stunden pro Woche (5 Tage) pro Tag 08:24 Stunden wert ist.',
    start_company_week_conv_placeholder: 'ss:mm',
    start_company_vacations:
      'Auf wie viele Urlaubstage haben Sie pro Jahr Anspruch?',
    start_company_vacations_placeholder: 'Tagen',
    start_company_vacations_help: 'Normalerweise 20, 25 oder 30 Tage pro Jahr',
    start_company_confirm: 'Starten Sie WoTime!',

    signin_title: 'Login WoTime',
    signin_subtitle: 'Wenn Sie Problem haben Login in bitte an den Support',
    signin_email: 'Email',
    signin_password: 'Passwort',
    signin_sign_in: 'Einloggen',
    signin_sign_up: 'Ein Konto erstellen?',
    signin_lost: 'Passwort verloren?',
    password_lost_title: 'Passwort verloren',
    password_lost_email: 'Email',
    password_lost_email_help:
      'Sie erhalten eine E-Mail mit einem Link erhalten Sie Ihr Passwort ändern',
    password_lost_confirm: 'Schicke den Link',
    password_lost_success:
      'Bitte überprüfen Sie Ihre Mailbox Ihr Passwort ändern',

    password_change_title: 'Passwort ändern',
    password_change_subtitle:
      'Bitte geben Sie Ihr altes Passwort und Ihr neues Passwort ein und bestätigen Sie das neue Passwort erneut    ',
    password_change_password_old: 'aktuelles Passwort',
    password_change_password_new: 'neues Passwort',
    password_change_password_confirm: 'Bestätigung des neuen Passworts',
    password_change_confirm: 'Passwort ändern',
    password_change_success: 'Ihr Passwort wurde geändert',

    signup_title: 'Einen Account registrieren',
    signup_subtitle: 'Bitte geben Sie Ihre E-Mail und wählen Sie ein Passwort',
    signup_email: 'Email',
    signup_email_required: 'Bitte geben Sie E-Mail-Adresse',
    signup_email_invalid: 'überprüfen Sie bitte Ihre E-Mail-Adresse',
    signup_password: 'Passwort',
    signup_password_required: 'bitte wählen und ein Kennwort',
    signup_password_length_min:
      'Das Passwort muss 8 oder mehr Zeichen lang sein',
    signup_given_name: 'Vorname',
    signup_given_name_required: 'Bitte geben Sie Ihren Vornamen',
    signup_family_name: 'Familienname, Nachname',
    signup_family_name_required: 'Bitte geben Sie Ihren Namen',
    signup_sign_in: 'Sie haben bereits ein Konto?',
    signup_sign_up: 'Registrieren',
    tasks_title: 'Aufgaben',
    tasks_subtitle:
      'Die Aufgaben unten können Sie die Wartung Ihrer Datenbank durchzuführen.',
    tasks_continue: 'Fortsetzen',
    tasks_launch: 'Starten Sie Task-',
    task_sheets_generate_title: 'Generieren Arbeitswochen',
    task_sheets_generate_subtitle:
      'Generieren Arbeitswochen und Arbeitswochen Einträge von Passagen und Kalenderereignisse',
    task_sheets_generate_weeks:
      'Anzahl der Wochen in der Vergangenheit zu erzeugen',
    task_sheets_generate_loaded: 'Geladen {count} {type}',
    task_sheets_generate_found: 'Gefunden bestehende Arbeitswoche {id}',
    task_sheets_generate_new: 'Computing Arbeitswoche {id}',
    task_sheets_generate_unmodified: 'Arbeitswoche {id} wurde nicht geändert',
    task_sheets_generate_updated: 'Arbeitswoche {id} wurde aktualisiert',
    task_sheets_compute_title: 'Recompute Arbeitswochen',
    task_sheets_compute_subtitle:
      'die Arbeitswochen Summen für alle Benutzer neu berechnen',
    task_sheets_compute_weeks: 'Anzahl der Wochen neu zu berechnen',
    planning_title: 'Planung',
    planning_subtitle: 'Alle Benutzer Ereignisse an einem Ort',
    planning_current: 'aktueller Monat',
    planning_accounts: 'Konten',
    planning_org: 'Organisation',
    planning_holidays: 'In Feiertagen für die Planung',
    planning_month_current: 'aktueller Monat',
    planning_retro: 'vergangene Ereignisse von Arbeitsblatt',
    company_holidays_year_choose: 'Wählen Sie ein Jahr',
    company_holidays_state_choose: 'Wählen Sie einen Kanton',
    company_holidays_days_choose: 'wählen Sie Urlaub',
    company_holidays_days_help:
      'Samstag und Sonntag sind nicht standardmäßig aktiviert. Nur fügen Sie ist, dass Sie Samstag oder Sonntag ein Arbeitstag in Betracht ziehen.',
    company_holidays_accounts_choose:
      'Wählen Sie die Konten die Feiertage empfangen',
    retro_planning_planning: 'Planung',
    requests_title: 'Kalender Event Anfragen',
    requests_subtitle: 'Sie können die Ereignisse markieren als validiert',
    requests_empty: 'Alle Anforderungen wurden erfüllt!',
    org_title: 'Benutzer und Teams',
    org_subtitle: 'Organisieren Sie die Benutzer und Teams für die Planung',
    org_name: 'Name',
    org_team_add: 'Hinzufügen eines Teams',
    org_management_sheets_report_overtime_hide: 'ausblenden Überstunden',
    org_management_sheets_report_overtime_hide_help:
      'Sie nicht die Zeiteinträge in der Verwaltungs Work Report zeigen.',
    org_dropzone: 'Drop Artikel hier',
    org_dropzone_remove: 'Drop hier Element, um es zu entfernen',
    company_rights_title: 'Urlaub Rechte',
    company_rights_subtitle:
      'Aktualisieren Sie alle Ferienrechte für einen Zeitraum auf einmal',
    company_rights_account: 'Konto',
    company_rights_week_year: 'Jahr',
    company_rights_name: 'Name',
    company_rights_week_start: 'Von',
    company_rights_week_end: 'Bis um',
    company_rights_previous: 'Tragen',
    company_rights_contract: 'nach Vertrag',
    company_rights_total: 'Gesamt',
    company_rights_accepted: 'Akzeptiert',
    company_rights_carry_title: 'Carry Urlaub Reste aus dem Vorjahr',
    company_rights_actions: 'Aktionen',
    company_rights_create: 'speichern',
    company_rights_new: 'Neue Urlaub Rechte',
    company_rights_carry: 'Carry auf das nächste Jahr',
    company_report_sheets_title: 'Firma Blätter Berichte',
    company_report_sheets_title_alt:
      'Firma Blätter Berichte aus {weekStart} in {} WOCHENENDE',
    company_report_sheets_subtitle: 'Fasst die Arbeitswochen Zähler',
    company_report_sheets_changes: 'Einige arbeiten Blatt wurden geändert',
    company_report_sheets_reprocess: 'Recompute Bericht',
    company_report_sheets_employees: 'Angestellte',
    company_report_sheets_sheets: 'Blätter',
    company_report_sheets_accepted: 'Akzeptiert',
    company_report_sheets_warning: 'Warnung',
    company_report_sheets_error: 'Error',
    company_report_work_from: 'von Woche',
    company_report_work_until: 'bis Woche',
    company_report_work_build: 'Build-Bericht',
    company_report_vacations_title: 'Firma Urlaubsberichte',
    company_report_vacations_title_alt:
      'Firma Urlaubsberichte von {weekStart} in {} WOCHENENDE',
    company_report_vacations_subtitle: 'Fasst die restlichen Urlaub Rechte',
    company_report_vacations_changes:
      'Einige Arbeitsblätter oder im Urlaub richtig wurden geändert',
    company_report_vacations_reprocess: 'Recompute Bericht',
    company_report_vacations_employees: 'Angestellte',
    company_report_vacations_sheets: 'Blätter',
    company_report_vacations_accepted: 'Akzeptiert',
    company_report_vacations_initial: 'Initiale',
    company_report_vacations_used: 'Benutzt',
    company_report_vacations_remaining: 'Verbleibend',
    company_report_vacations_edit: 'Bearbeiten Sie alle Rechte',
    company_report_vacations_prepare: 'Bereiten Sie im nächsten Jahr Rechte',
    company_report_passages_title: 'Passages Firmabericht',
    company_report_passages_subtitle:
      'Schauen Sie sich die Passagen für einen Tag',
    company_report_passages_changes: 'Einige Passagen wurden geändert',
    company_report_passages_reprocess: 'Recompute Bericht',
    company_report_passages_date: 'Passages Datum',
    company_report_passages_employees: 'Angestellte',
    company_report_passages_passages: 'Passages',
    company_report_passages_ignored: 'Diskrepanzen',
    company_report_passages_work_week: 'Arbeitswoche',
    company_report_passages_events: 'Veranstaltungen',
    subscription_title: 'Abonnement',
    subscription_subtitle:
      'Überprüfen Sie Ihre Nutzungsdaten und aktualisieren Sie Ihre Zahlungsinformationen',
    subscription_subscription: 'Abonnement',
    subscription_status: 'Status',
    subscription_cost: 'Kosten',
    subscription_users_current: 'Derzeitige Nutzer',
    subscription_user: 'Benutzer',
    subscription_month: 'Monat',
    subscription_users:
      'Benutzer aktiv in dem Monat | Benutzer aktiv in dem Monat',
    subscription_trial_period: 'Probezeit',
    subscription_trial_period_days: 'Tage',
    subscription_trial_end: 'Ende der Studie',
    subscription_warranty: 'Garantie',
    subscription_warranty_text: '100% Zufrieden oder Geld zurück',
    subscription_terms: 'Anzeige der Begriff & Bedingungen',
    subscription_invoices_title: 'Rechnungen',
    subscription_invoices_number: 'Nummer',
    subscription_invoices_date: 'Datum',
    subscription_invoices_status: 'Status',
    subscription_status_past_due: 'überfällig',
    subscription_invoices_status_paid: 'Bezahlt',
    subscription_invoices_due_date: 'Fällig',
    subscription_invoices_period_start: 'Periode ab',
    subscription_invoices_period_end: 'Zeitraum bis',
    subscription_invoices_amount_due: 'Offener Betrag',
    subscription_invoices_amount_paid: 'Betrag bezahlt',
    subscription_invoices_currency: 'Währung',
    subscription_cc_title: 'Kreditkarte',
    subscription_cc_brand: 'Marke',
    subscription_cc_number: 'Endziffer',
    subscription_cc_expires: 'Läuft ab',
    subscription_cc_usage: 'Verwendung',
    subscription_cc_actions: 'Aktionen',
    subscription_cc_default: 'für Ladungen verwendet',
    subscription_cc: 'Kreditkarte',
    subscription_cc_add: 'Kreditkarte hinzufügen',
    subscription_cc_add_error:
      'Temporärer Fehler, während die Kreditkarte hinzufügen, versuchen Sie es später erneut',
    subscription_cc_remove: 'Entfernen',
    terms_title: 'Terms & amp; Bedingungen',
    terms_subtitle:
      'Die Allgemeinen Geschäftsbedingungen sind nur auf französisch. Bitte kontaktieren Sie uns, wenn Sie in der Notwendigkeit einer Übersetzung sind.',
    company_tag: 'Firma',
    company_title: 'Firmeneinstellungen',
    company_management: 'Verwaltung',
    company_reports: 'Lageberichte',
    company_report_sheets: 'Arbeitsbericht',
    company_report_sheets_help:
      'Zusammenfassung aller Arbeitswochen Zähler für alle Mitarbeiter',
    company_report_vacations: 'Urlaubsbericht',
    company_report_vacations_help:
      'Zusammenfassung der Ferien die übrigen und für alle Mitarbeiter geplant',
    company_report_passages: 'Passages Bericht',
    company_report_passages_help:
      'Überprüfen Sie die Passagen aller Mitarbeiter von Tag',
    company_audit_trail: 'Buchungskontrolle',
    company_audit_trail_help: 'Aufzeichnungen Änderungsverfolgung',
    company_accounts_title: 'Konten',
    company_accounts: 'Konten verwalten',
    company_accounts_help:
      'Fügen Sie ein neues Konto, ändern Sie die Konto Rolle und Zugängen',
    company_orgs: 'Organisieren Konten und Teams',
    company_orgs_help: 'Gruppenkonten Teams mit',
    company_org: 'organisieren Konten',
    company_org_help: 'Gruppenkonten Teams mit',
    company_settings: 'Firmeneinstellungen',
    company_settings_help:
      'Aktualisieren Sie Ihren Firmennamen, die Zeiteinträge Modell und die extentions',
    company_settings_name: 'Name der Firma',
    company_settings_name_help: 'Ändern Sie den Namen Ihrer Firma',
    company_settings_defaults: 'Neue Konten-Einstellungen',
    company_settings_defaults_help:
      'Defaults arbeiten Woche und Urlaub Einstellungen',
    company_settings_models: 'Arbeitswochen Einträge',
    company_settings_models_help: 'Ändern Sie die Darstellung der Einträge',
    company_settings_extensions: 'Erweiterungen',
    company_settings_extensions_help:
      'Verschiedene Arbeitszeiterfassung und Kontrollen',
    company_subscription: 'Abonnements',
    company_subscription_help: 'Zahlungsverfahren und Rechnungen',
    company_name: 'Name der Firma',
    company_contract_form: 'bilden',
    company_contract_capital: 'Hauptstadt',
    company_contract_ide: 'IDE',
    company_contract_street: 'Straße',
    company_contract_postal_code: 'Postleitzahl',
    company_contract_city: 'Stadt',
    company_contract_contact: 'Kontakt',
    company_contract_position: 'Position',
    company_contract_label: 'Etikette',
    company_contract_price: 'Preis',
    company_contract_email: 'Email',
    company_contract_currency: 'Währung',
    company_contract_created: 'erstellt',
    company_extension_overlaps:
      'Überprüfen Sie überlappende Arbeitswoche Einträge',
    company_extension_overlaps_help:
      'Überprüfen Sie, ob zwei Zeiteinträge am selben Tag überlappen.',
    company_extension_ltr15: 'Artikel 15',
    company_extension_ltr15_help:
      'Wenden Sie obligatorisch in Artikel beschriebenen Pausen 15 der Schweizer Arbeitsrecht',
    'company_extension_ltr15-wc': 'Article 15 with margins (Webcor)',
    'company_extension_ltr15-wc_help':
      'Apply mandatory pauses described in article 15 of Swiss Labor Law with some margins. When work > 9.5 hours then 1 hour of pause, when work > 7.5 hours then 30 minutes of pause, and when work > 5.75 hours -> 15 minutes of pause',
    company_entry_models: 'Arbeitswochen Einträge Modelle',
    company_extension_pause30: 'Pause <30',
    company_extension_pause30_help:
      'Warnen Sie den Benutzer, wenn er den Beginn und das Ende von Pausen über 30 Minuten nicht dokumentiert',

    company_entry_models_help:
      'Passen Sie die Farben der Einträge, die bei der Planung angezeigt und in den Arbeitswochen Blick',
    company_extensions: 'Erweiterungen',
    company_extensions_help:
      'Wählen Sie, welche Erweiterung Zeiterfassungs Berechnung gelten. Wenn Sie eine andere Erweiterung benötigen bitte in Verbindung.',
    company_account_week_settings: 'Neues Konto Wocheneinstellungen',
    company_account_week_settings_help:
      'Wenn ein neues Konto der Standardwert erstellt wird, wird im Folgenden verwendet werden,',
    trail_timestamp: 'Zeitstempel',
    trail_user: 'Benutzer',
    trail_account: 'Konto',
    trail_record: 'Sammlung',
    trail_id: 'Aufzeichnung',
    users_title: 'Benutzerverwaltung',
    users_subtitle: 'Neuen Benutzer hinzufügen, geben Sie den Benutzerzugriff',
    user: 'Benutzer',
    accounts_org: 'organisieren Konten',
    accounts_org_view: 'Organisierte Konten',
    accounts_org_all: 'Alle Konten',
    accounts_settings: 'Einstellungen',
    accounts_archive: 'Archiv',
    weekday_entry_from: 'von',
    weekday_entry_until: 'bis',
    weekday_entry_day: 'tag',
    account_sheets: 'Arbeitsblätter',
    account_sheets_help: 'Eingang und überprüfen Sie Ihre Arbeitszeit',
    account_events: 'Kalenderereignisse',
    account_events_help: 'Plan für den Urlaub überprüfen feiertags',
    account_rights: 'Urlaubsrechte',
    account_rights_help:
      'Notieren Sie die Urlaubsrechte verträglich und Rest aus dem letzten Jahr',
    account_reports: 'Berichte',
    account_report_work: 'Arbeitsbericht',
    account_report_work_help: 'Zusammenfassung aller Zähler für das Jahr',
    account_report_vacations: 'Urlaubsbericht',
    account_report_vacations_help:
      'Zusammenfassung der Ihren Urlaub für das Jahr',
    account_report_passages: 'Passages Bericht',
    account_report_passages_help:
      'Sehen Sie, wenn Sie Punch-In und Punch-Out der Arbeit',
    account_settings: 'Einstellungen',
    account_settings_help: 'Ändern allgemeine Woche und Jahr Einstellungen',
    account_permissions: 'Rollen und Zugriffsrechte',
    account_permissions_help:
      'Manager-Rolle, Rolle Mitarbeiter, Lese- und Schreibzugriffe',
    account_add_title: 'Fügen Sie einen Benutzer',
    account_add_subtitle: 'Laden Sie einen Benutzer WoTime zu verwenden',
    account_add_email: 'Email',
    account_add_given_name: 'Vorname',
    account_add_family_name: 'Familienname',
    account_add_email_help:
      'Der Benutzer wird eine E-Mail einladen, ihn zu WoTime in Ihrem Namen erhält. Diese E-Mail enthält Anweisungen zum Herstellen der Verbindung.',
    account_add_uid: 'Benutzeridentifikation',
    account_add_password: 'Passwort',
    account_add_confirm: 'Benutzerkonto anlegen',
    accounts_add: 'Ein Konto erstellen',
    users_view_archived: 'Archivierte Benutzer',
    sheets_title: 'Arbeitswochen für {name}',
    sheets_subtitle:
      'Arbeitswochen können Sie für Woche Ihre Arbeitszeiten und Urlaub verfolgen',
    sheets_view_archived: 'Archivierte Arbeitswochen',
    sheets_index_refresh: 'Indexieren',
    sheet_card_work_week: 'Arbeitswoche',
    sheet_card_from: 'von',
    sheet_card_to: 'zu',
    sheet_card_accepted: 'Akzeptiert',
    sheet_remove_confirm:
      'Möchten Sie die Arbeitswoche {name} wirklich entfernen?',
    sheet_settings_due: 'Arbeit fällig',
    sheet_settings_due_cap: ' ',
    sheet_settings_max: 'Max Arbeit',
    sheet_settings_max_cap:
      'Bist du sicher? Dieser Wert liegt über der maximale gesetzliche Zeit (40-45 Stunden).',
    sheet_settings_conv: 'Ein Tag besteht aus',
    entry_span_from: 'hh: mm',
    entry_span_until: 'hh: mm',
    entry_model_modal_filter: 'Filtern Sie die Modelle ...',
    entry_note: 'Hinweis',
    entry_model_picker_search: 'Suche…',
    period_buttons_label: 'Perioden',
    sheets_work_week: 'Arbeitswoche',
    sheets_accept: 'Akzeptieren',
    sheets_archive: 'Archiv',
    sheets_unarchive: 'unarchive',
    sheets_create_first: 'Erstellen Sie die erste Arbeitswoche',
    sheets_create_next: 'Erstellen {week} Arbeitswoche',
    sheets_create_previous: 'Erstellen {week} Arbeitswoche',
    sheet_title: 'Arbeitswoche',
    sheet_subtitle:
      'Beschreiben Sie Ihre Arbeitszeiten, Pausen, Urlaub und Ferien',
    sheet_validated:
      'Diese Arbeitswoche wird als validiert markiert. Wenn Sie einige Änderungen vornehmen wird es seinen Tag verlieren ..',
    sheet_copy_previous: 'Kopieren Sie Einträge aus früheren',
    sheet_generate: 'Aktualisieren die Einträge',
    sheet_generate_tooltip:
      'Generieren Sie Einträge aus Passagen und Veranstaltungen',
    sheet_versions: 'Vorherige Versionen',
    account_sheet_create: 'Erstellen Sie Arbeitswoche',
    update_info_by: 'aktualisiert von',
    update_info_on: 'am',
    sheet_revs_title: 'Arbeitswoche Versionen',
    sheet_revs_subtitle:
      'Sie können zu einer früheren Version der Arbeitswoche Rollback.',
    sheets_revs_rev: 'Display-Version',
    sheet_revs_restore: 'Wiederherstellen dieser Version',
    week_day_more: 'Mehr',
    week_total: 'Totals',
    sheet_summary_inputs: 'Was in diesem Blatt inputed wurde',
    sheet_summary_computed: 'Und was für dieses Blatt berechnet',
    week_summary: 'Zusammenfassung',
    week_settings: 'Wocheneinstellungen',
    entry_duration_placeholder: 'hh: mm',
    events_title: 'Kalender Ereignisse',
    events_subtitle:
      'Kalender Ereignisse Hilfeplan und später füllen Sie Ihre Arbeitswochen',
    events_add: 'Eine Veranstaltung hinzufügen',
    events_planning: 'events_planning',
    event_add_vacations: 'Fügen Sie einen Urlaub',
    events_holidays_add: 'Fügen Sie Feiertage für Ihre Region',
    events_view_past_events: 'anzuzeigen vergangene Ereignisse',
    event_tag: 'Veranstaltung',
    event_card_event: 'Veranstaltung',
    event_card_accept: 'Akzeptieren',
    holidays_title: 'Fügen Sie Feiertage für Ihre Region',
    holidays_subtitle:
      'Bitte wählen Sie die Region, die Stadt und die Einhaltung',
    holidays_info_single: 'Sie fügen Feiertage für einen einzelnen Benutzer.',
    holidays_info_also: 'Sie können auch ein feiertags {0} hinzufügen.',
    holidays_info_all: 'für alle Mitarbeiter',
    holidays_state: 'Bitte wählen Sie den Schweizer Kanton',
    holidays_year: 'Jahr',
    holidays_add_all: 'Fügen Sie alle Feiertage unten aufgeführt',
    event_holiday_choose: 'Wählen Sie einen Kanton',
    events_holidays_check_title: 'Ferien',
    events_holidays_check_subtitle:
      'Urlaub und Nicht-Arbeitstage für alle Kantone',
    events_holidays_check_label: 'Etikette',
    events_holidays_check_date: 'Datum',
    events_holidays_check_type: 'Art',
    event_per_day: 'pro Tag',
    event_title: 'Kalenderereignis',
    event_subtitle:
      'Kalender Ereignisse Hilfeplan und später füllen Sie Ihre Arbeitswochen',
    event_save: 'Save Event',
    event_remove: 'Entfernen',
    event_remove_confirm: 'Möchten Sie das Ereignis {name} wirklich entfernen?',
    event_remove_all: 'Alles entfernen',
    event_all_day: 'Ganztägig',
    event_all_day_text: 'Ganztägig kann mehrere Tage dauern',
    event_modal_all_accounts_create: 'Erstellen Sie für alle Konten',
    event_modal_all_accounts_create_description:
      'ein Ereignis für alle Konten erstellen',
    event_account: 'Konto',
    event_start_date: 'Von',
    event_end_date: 'Bis um',
    event_start_time: 'Von',
    event_end_time: 'Bis um',
    event_entry: 'Postenart',
    event_entry_pick: 'Wählen Sie einen Eintrag Typ',
    event_weekday_exception: 'Die Veranstaltung wird keine Anwendung auf',
    event_weekday_exception_mon: 'Mo',
    event_weekday_exception_tue: 'Di',
    event_weekday_exception_wed: 'Mi',
    event_weekday_exception_thu: 'Do',
    event_weekday_exception_fri: 'Fr',
    event_weekday_exception_sat: 'Sa',
    event_weekday_exception_sun: 'So',
    event_attachments: 'Anhänge',
    event_attachments_upload: 'Anhang hochladen',
    event_note: 'Hinweis',
    event_status: 'Validierungsstatus',
    right_card_label: 'Urlaubsrecht',
    rights_title: 'Urlaubsrechte',
    rights_subtitle:
      'Feriensrechte ermöglichen es Ihnen, Ihren Urlaub Nutzung pro Periode verfolgen',
    right_from: 'von',
    right_until: 'bis um',
    right_part_from: 'von',
    right_part_until: 'bis um',
    right_part_days: 'Tage',
    right_part_base: 'Base',
    right_part_vacations: 'Urlaube',
    right_part_add: 'fügen Sie eine neue Zeit',
    rights_add: 'Fügen Sie einen Urlaubsrecht',
    right: 'Arbeit fällig',
    right_edit_title: ' ',
    right_edit_subtitle:
      'Feriensrechte ermöglichen es Ihnen, Ihren Urlaub Nutzung pro Periode verfolgen',
    right_start_date: 'Von',
    right_end_date: 'Bis um',
    right_week_year: 'Jahr',
    right_vacations_contract: 'Urlaub direkt für das Jahr (Tage)',
    right_vacations_contract_short: 'Zeitraum',
    right_vacations_previous: 'Rest aus dem Vorjahr (Tage)',
    right_vacations_previous_short: 'Bisherige',
    right_vacations: 'Insgesamt Urlaub rechts',
    right_compute: 'Berechnen',
    right_vacations_short: 'Gesamt',
    right_vacations_computed: 'Urlaub Computer',
    right_note: 'Hinweis',
    right_save: 'Speichern Rechts',
    right_remove: 'Entfernen',
    reports_actions: 'Set Perioden',
    reports_summaries: 'Arbeitswoche Zusammenfassungen',
    reports_vacations: 'Urlaube',
    reports_passages: 'Passages',
    report_sheets_title: 'Arbeitswochen Bericht',
    report_sheets_subtitle: 'Fasst die Arbeitswochen Zähler',
    report_sheets: 'Arbeitswochen Bericht',
    report_sheets_start_date: 'Von',
    report_sheets_end_date: 'Bis um',
    report_sheets_details: 'Einzelheiten',
    report_sheets_details_help: 'Anzeige alle Blatteinträge',
    report_sheets_sheets: '{weeks} Woche | {weeks} Wochen',
    report_sheets_accepted: 'akzeptiert',
    report_sheets_from: 'von',
    report_sheets_until: 'bis um',
    report_vacations_title: 'Urlaubsbericht',
    report_vacations_for: 'zum',
    report_vacations_subtitle: 'Fasst die restlichen Urlaub Rechte',
    report_vacations_sheets: '{weeks} Woche | {weeks} Wochen',
    report_vacations_start_week: 'von Woche',
    report_vacations_end_week: 'bis Woche',
    report_vacations_end_right: 'Geplanten Urlaub bis',
    report_vacations_end_right_help:
      'zählen die Ferien, die auf dem Kalender geplant und noch nicht verwendet',
    report_vacations_details: 'Einzelheiten',
    report_vacations_details_help:
      'Anzeige Einträge für die Berechnung verwendet',
    report_vacations: 'Urlaubsbericht',
    report_vacations_start_date: 'Von',
    report_vacations_end_date: 'Bis um',
    report_vacations_right: 'Richtig',
    report_vacations_usage: 'Verwendung',
    report_vacations_week: 'Woche',
    report_vacations_day: 'Tag',
    report_vacations_amount: 'Menge',
    report_vacations_note: 'Hinweis',
    report_vacations_op_right: 'Urlaub rechts',
    report_vacations_op_used: 'Benutzt',
    report_vacations_op_planned: 'Geplant',
    report_vacations_event_date: 'Datum',
    report_vacations_event_amount: 'Urlaube',
    report_vacations_event_note: 'Hinweis',
    report_vacations_op_remaining: 'Verbleibend',
    report_passages_title: 'Passages Bericht',
    report_passages_subtitle:
      'Überprüfen Sie die Passagen für die aktuelle Woche',
    report_passages_week: 'Woche',
    report_passages_not_found: 'Keine Passage wurde für diese Woche gefunden.',
    account_title: 'Benutzer',
    account_title_alt: 'Benutzer {firstName} {lastName}',
    account_general: 'Allgemeines',
    account_given_name: 'Vorname',
    account_family_name: 'Familienname',
    account_email: 'Email',
    account_email_help:
      'en E-Mail-Adresse zu ändern kontaktieren Sie bitte den Support',
    account_role: 'Rolle',
    account_roles_user: 'Mitarbeiter',
    account_roles_user_help: 'kann nur seine eigenen Daten zugreifen',
    account_roles_manager: 'Manager',
    account_roles_manager_help: 'können alle Mitarbeiterdaten zugreifen',
    account_access: 'Zugriff',
    account_accesses_rw: 'lesen & schreiben',
    account_accesses_rw_help: 'anzeigen und aktualisieren können die Daten',
    account_accesses_ro: 'schreibgeschützt',
    account_accesses_ro_help: 'anzeigen können nur die Daten',
    account_planning: 'Planung',
    account_planning_help: 'Lesen Sie nur Zugriff auf die Firmaplanung',
    account_week_settings: 'Wocheneinstellungen',
    account_week_settings_simple: 'einfach',
    account_week_settings_alternate: 'wechselnd',
    account_week_settings_help:
      'Die Wärte hierunten werden bei der nächsten Erstellung der Woche verwendet.',
    account_week_settings_due: 'Arbeit fällig pro Woche',
    account_week_settings_due_odd:
      'Arbeit fällig ungeraden Wochen (W01, W03 ...)',
    account_week_settings_due_even: 'Arbeit fällig sogar Wochen (W02, W04 ...)',
    account_week_settings_max: 'Max Arbeit pro Woche',
    account_week_settings_max_helper:
      'Wenn Sie mehr als die Höchstarbeitsmenge arbeiten, wird die Zeit als zusätzliche Zeit gezählt',
    account_week_settings_conv: 'Ein Tag der Arbeit in Stunden',
    account_year_settings: 'Jahr Einstellungen',
    account_year_settings_help:
      'Die Wärte hierunten werden bei der nächster Erstellung des Urlaubsrechtes verwendet.',
    account_year_settings_vacations: 'Wie viele Tage Urlaub pro Jahr?',
    account_keycards_settings: 'keycards',
    account_keycards_settings_help:
      'Automaticaly schaffen Passagen aus den Keycard-Gate Veranstaltungen',
    account_keycards: 'keycards',
    account_keycards_help: 'Eingabe einer Keycard pro Zeile',
    passages_title: 'Passages',
    passages_subtitle:
      'Holen Sie sich Ihre Arbeitszeit fill automaticaly durch Passagen aus Ihrem iPhone oder Android-Handy einrichten.',
    passages_phone_connect:
      'Sie müssen die Passagen aus der Anwendung auf Ihrem Telefon einrichten verbunden werden',
    settings_title: 'Einstellungen',
    settings_subtitle: 'Einstellungen',
    permissions_account: 'Konto',
    permissions_active: 'aktiv',
    permissions_archived: 'archivierten',
    permissions_roles: 'Rollen',
    permissions_employee: 'Mitarbeiter',
    permissions_manager: 'Manager',
    permissions_planning: 'Planungsansicht',
    permissions_access: 'Zugangsberechtigung',
    permissions_ro: 'schreibgeschützt',
    permissions_rw: 'lesen & schreiben',
    error_report_title: 'Error',
    error_title: 'Hoppla, ein Fehler ist aufgetreten :-(',
    about_bc: 'WoTime',
    about_title: 'Über WoTime',
    about_subtitle:
      'Hier finden Sie alle Details der aktuellen Version von WoTime',
    about_version: 'Version',
    about_build_date: 'Gebaut am',
    about_support: 'Support',

    translations_title: 'Übersetzungen',
    translations_subtitle: 'Liste aller WoTime Übersetzungen',
    translations_key: 'Schlüssel',
    translations_en: 'English',
    translations_fr: 'Français',
    translations_de: 'Deutsch',
    company_name_placeholder: 'Firma',
    days: 'Tag | Tage',
    days_short: 'd',
    weekdays_1_short: 'Mon',
    weekdays_2_short: 'Die',
    weekdays_3_short: 'Mit',
    weekdays_4_short: 'Don',
    weekdays_5_short: 'Fre',
    weekdays_6_short: 'Sam',
    weekdays_7_short: 'Son',
    weekdays_1_long: 'Montag',
    weekdays_2_long: 'Dienstag',
    weekdays_3_long: 'Mittwoch',
    weekdays_4_long: 'Donnerstag',
    weekdays_5_long: 'Freitag',
    weekdays_6_long: 'Samstag',
    weekdays_7_long: 'Sonntag',

    not_found_title: 'Nicht gefunden',
    not_found_subtitle: 'Die Ressource kann nicht gefunden werden.',

    firebase_auth_email_already_in_use:
      'Die E-Mail-Adresse ist bereits von einem anderen Konto registriert',
    firebase_auth_invalid_email: 'Diese E-Mail-Adresse ist ungültig',
    firebase_auth_operation_not_allowed:
      'Dieser Vorgang kann nicht ausgeführt werden',
    firebase_auth_weak_password:
      'Das Passwort ist zu schwach und kann zu leicht erraten werden',
    firebase_auth_user_disabled: 'Dieses Benutzerkonto ist deaktiviert',
    firebase_auth_user_not_found: 'Benutzerkonto nicht gefunden',
    firebase_auth_wrong_password: 'Falsches Passwort',
    firebase_auth_too_many_requests:
      'Es wurden zu viele Anfragen gestellt. Bitte versuchen Sie es später erneut',
    sqr_fire_upload_dnd_available: 'Drag & Drop-Dateien zum Hochladen',

    validation_email: 'Dies ist keine gültige e-mail Adresse',
    validation_required: 'Dieses Feld wird benötigt',
    partner_stsa_register_title: 'STSA Mitglieder',
    partner_stsa_register_subtitle:
      'STSA-Mitglieder profitieren von einem Rabatt von 30%. Der Preis für einen Benutzer pro Monat beträgt 6 CHF (statt 9 CHF) • 30 kostenlose Testversion • 100% Zufrieden oder Geld zurück',

    statuses_pending: 'Pending',
    statuses_pending_select: 'Unlock',
    statuses_accepted: 'Akzeptiert',
    statuses_accepted_select: 'Akzeptieren',
    statuses_denied: 'Verweigert',
    statuses_denied_select: 'Verweigern',
    status_locked:
      'Wenn Sie diesen Datensatz bearbeiten möchten, verlieren Sie seinen Validierungsstatus. Möchten Sie den Datensatz wirklich entsperren?',
    status_locked_event:
      'Wenn Sie dieses Ereignis bearbeiten möchten, verlieren Sie seinen Validierungsstatus. Möchten Sie das Ereignis wirklich freischalten?',
    status_unlocked: 'Freischalten',

    pagination_next: 'Nächste Seite',
    pagination_previous: 'Vorherige',
    pagination_goto: 'Seite {page}'
  }
};
