export default {
  props: {
    tooltip: String,
    tooltipRight: Boolean,
    tooltipRaw: String,
  },
  computed: {
    tooltipTr() {
      return this.tooltipRaw || this.$t(this.tooltip) || null;
    },
    tooltipClass() {
      return {
        tooltip: !!this.tooltipTr,
        'tooltip-right': this.tooltipRight,
      };
    },
  },
};
