<template>
  <div v-if="status">
    <sqr-progress
      class="is-marginless is-primary"
      v-if="status === 'loading'"
    />
    <div class="banner">
      <div class="container">
        <nav class="level">
          <div class="level-left">
            <div class="level-item">
              <div class="title is-5">{{ $t('update_banner_title') }}</div>
            </div>
            <div class="level-item">
              <div class="title is-6">{{ $t('update_banner_' + status) }}</div>
            </div>
          </div>
          <div class="level-right">
            <div class="level-item"></div>
            <div class="level-item">
              <a
                class="button is-primary"
                :class="{ 'is-loading': updating }"
                @click="update()"
              >
                {{ $t('update_banner_action') }}
              </a>
            </div>
          </div>
        </nav>
      </div>
    </div>
  </div>
</template>

<style scoped>
.banner {
  padding: 1rem 0 1rem 0;
  background-color: #edeaeb;
}
</style>

<script>
import SqrProgress from '@/sqrd/SqrProgress';
export default {
  name: 'update-banner',
  components: {
    SqrProgress,
  },
  data() {
    return {
      status: '',
      registration: null,
    };
  },
  computed: {
    updating() {
      return this.status === 'updating';
    },
  },
  created() {
    document.addEventListener('swUpdated', this.updateFound, { once: true });
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (this.status === 'updating') return;
      this.status = 'updating';
      window.location.reload();
    });
  },
  methods: {
    updateFound(e) {
      this.status = 'ready';
      this.registration = e.detail;
    },
    update() {
      if (!this.registration || !this.registration.waiting) {
        return;
      }
      this.registration.waiting.postMessage('skipWaiting');
    },
  },
};
</script>
