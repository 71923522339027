<template>
  <div class="navbar-item has-dropdown is-hoverable" v-if="record && isManager">
    <router-link
      :to="{ name: 'company', params }"
      class="navbar-link is-arrowless"
    >
      {{ $t('navbar_company_management') }}
    </router-link>
    <div class="navbar-dropdown is-hidden-mobile" v-if="params">
      <router-link class="navbar-item" :to="{ name: 'planning', params }">{{
        $t('navbar_company_planning')
      }}</router-link>
      <router-link
        class="navbar-item"
        :to="{ name: 'company-requests', params }"
        >{{ $t('navbar_company_requests') }}</router-link
      >
      <!-- <router-link class="navbar-item" :to="{name: 'company-rights', params}">{{$t('navbar_company_rights')}}</router-link> -->
      <div class="navbar-divider"></div>
      <router-link
        class="navbar-item"
        :to="{ name: 'company-report-work', params }"
        >{{ $t('navbar_company_report_sheets') }}</router-link
      >
      <router-link
        class="navbar-item"
        :to="{ name: 'company-report-vacations', params }"
        >{{ $t('navbar_company_report_vacations') }}</router-link
      >
      <router-link class="navbar-item" :to="{name: 'company-report-passages', params}">{{$t('navbar_company_report_passages')}}</router-link>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
export default {
  name: 'NavManagement',
  computed: {
    ...mapState('company', ['id', 'record', 'loading', 'loadError']),
    ...mapGetters('perms', ['isManager']),
    params() {
      return { cid: this.id };
    },
  },
};
</script>
