<template>
  <router-link
    v-if="cid && aid && isEmployee"
    class="navbar-item"
    :to="{ name: 'sheets', params: { cid, aid } }"
  >
    {{ $t('navbar_account_sheets') }}
  </router-link>
</template>

<script>
import navAccount from './navAccount';
export default {
  mixins: [navAccount],
};
</script>
