<template>
  <main>
    <section class="section is-medium" v-if="!authenticated">
      <div class="columns is-centered">
        <div class="column is-12-mobile is-6-tablet is-4-desktop">
          <div class="buttons">
            <sqr-router-link
              label="home_sign_in"
              to-name="sign-in"
              color="dark"
              size="medium"
              is-fullwidth
              is-outlined
            />
            <sqr-router-link
              label="home_sign_up"
              to-name="sign-up"
              color="primary"
              size="medium"
              is-fullwidth
            />
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
// @ is an alias to /src
import { mapGetters } from 'vuex';
import SqrRouterLink from '@/sqrd/SqrRouterLink';

export default {
  name: 'Home',
  components: {
    SqrRouterLink,
  },
  computed: {
    ...mapGetters('auth', ['authenticated']),
  },
  mounted() {
    this.check(this.authenticated);
  },
  watch: {
    authenticated(value) {
      this.check(value);
    },
  },
  methods: {
    check(authenticated) {
      if (authenticated) {
        this.$router.push({ name: 'companies' });
      }
    },
  },
};
</script>
