<template>
  <div
    class="navbar-item has-dropdown is-hidden-mobile is-hoverable"
    v-if="cid && aid && isEmployee"
  >
    <a class="navbar-link is-arrowless">
      {{ $t('navbar_account_reports') }}
    </a>
    <div class="navbar-dropdown">
      <router-link
        v-if="cid && aid"
        class="navbar-item"
        :to="{ name: 'report-work', params: { cid, aid } }"
      >
        {{ $t('navbar_report_work') }}
      </router-link>
      <router-link
        v-if="cid && aid"
        class="navbar-item"
        :to="{ name: 'report-vacations', params: { cid, aid } }"
      >
        {{ $t('navbar_report_vacations') }}
      </router-link>
      <router-link
        v-if="cid && aid"
        class="navbar-item"
        :to="{name: 'report-passages', params: {cid, aid}}"
      >
        {{$t('navbar_report_passages')}}
      </router-link>
    </div>
  </div>
</template>

<script>
import navAccount from './navAccount';
export default {
  mixins: [navAccount],
};
</script>
