<template>
  <div>
    <nav-bar />
    <update-banner />
    <router-view />
  </div>
</template>

<script>
import NavBar from '@/components/NavBar';
import UpdateBanner from '@/components/UpdateBanner';
export default {
  name: 'WoTime',
  components: { NavBar, UpdateBanner },
};
</script>
