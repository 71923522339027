export default {
  namespaced: true,
  state: {
    online: true,
  },
  mutations: {
    online(state) {
      state.online = true;
    },
    offline(state) {
      state.online = false;
    },
  },
  actions: {
    init: {
      root: true,
      handler({ commit }) {
        window.addEventListener('offline', () => commit('offline'));
        window.addEventListener('online', () => commit('online'));
      },
    },
  },
};
