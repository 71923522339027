import Vue from 'vue';

function translateError(error) {
  if (error && error.code) {
    const code = 'firebase_' + error.code.replace(/\W/g, '_');
    const message = Vue.$locale.t(code);
    return { ...error, message };
  } else {
    return error;
  }
}

export default function () {
  let unsub;
  return {
    namespaced: true,
    state: {
      loading: false,
      user: null,
      claims: null,
      email: '',
      errors: [],
    },
    getters: {
      loading: state => state.loading,
      authenticated: state => !!state.user,
      user: state => state.user,
      uid: state => state.user?.uid,
      email: state => state.email,
      displayName: state => state.user?.displayName || state.user?.email,
      errors: state => state.errors,
    },
    mutations: {
      reset(state) {
        state.loading = false;
        state.errors = [];
      },
      loading(state) {
        state.loading = true;
        state.errors = [];
      },
      loaded(state) {
        state.loading = false;
        state.errors = [];
      },
      userSet(state, { user, claims }) {
        state.user = user;
        state.claims = claims;
      },
      fieldSet(state, { field, value }) {
        state[field] = value;
      },
      error(state, { error }) {
        state.errors.push(translateError(error));
        state.loading = false;
      },
    },
    actions: {
      init: {
        root: true,
        handler({ state, commit, dispatch }) {
          // Vue.$fb()
          //   .auth()
          //   .onAuthStateChanged(async userRaw => {
          //     if (userRaw) {
          //       const user = JSON.parse(JSON.stringify(userRaw));
          //       try {
          //         const token = await userRaw.getIdTokenResult();
          //         const claims = token.claims;
          //         context.dispatch('login', { user, claims }, { root: true });
          //       } catch (error) {
          //         context.dispatch('logout', {}, { root: true });
          //       }
          //     } else {
          //       context.dispatch('logout', {}, { root: true });
          //     }
          //   });

          // sign-in, sign-out, token change
          Vue.$fb()
            .auth()
            .onIdTokenChanged(async userRaw => {
              const user = JSON.parse(JSON.stringify(userRaw));
              if (user) {
                // commit('userSet', {user});
                await dispatch('idTokenGet', { refresh: false });
                dispatch(
                  'login',
                  { user, claims: state.claims },
                  { root: true }
                );
              } else {
                dispatch('logout', {}, { root: true });
              }
              commit('loaded');
            });
        },
      },
      reset(context) {
        context.commit('reset');
      },
      async createUserWithEmailAndPassword(context, { email, password }) {
        try {
          context.commit('loading');
          const result = await Vue.$fb()
            .auth()
            .createUserWithEmailAndPassword(email, password);
          context.commit('loaded');
          return result;
        } catch (error) {
          context.commit('error', { error });
          return Promise.reject(error);
        }
      },
      async signInWithEmailAndPassword(context, { email, password }) {
        try {
          context.commit('loading');
          const result = await Vue.$fb()
            .auth()
            .signInWithEmailAndPassword(email, password);
          context.commit('loaded');
          return result;
        } catch (error) {
          context.commit('error', { error });
          return Promise.reject(error);
        }
      },
      async displayNameSet(context, { displayName }) {
        try {
          return await Vue.$fb()
            .auth()
            .currentUser.updateProfile({ displayName });
        } catch (error) {
          context.commit('error', { error });
          return Promise.reject(error);
        }
      },
      async signOut({ commit }) {
        try {
          const result = await Vue.$fb().auth().signOut();
          commit('userSet', { user: null });
          return result;
        } catch (error) {
          return Promise.reject(error);
        }
      },
      async idTokenGet({ commit }, { refresh }) {
        // console.log('refreshing id token', refresh);
        const idTokenResult = await Vue.$fb()
          .auth()
          .currentUser.getIdTokenResult(refresh || false);
        const claims = idTokenResult.claims;
        commit('fieldSet', { field: 'claims', value: claims });
        return idTokenResult;
      },
      async claimsTrack({ state, dispatch }, { uid }) {
        if (unsub) unsub();
        unsub = Vue.$db()
          .collection('users-claims')
          .doc(uid)
          .onSnapshot(snap => {
            const userClaims = snap.data();
            // commit('fieldSet', { field: 'claimsUpdate', value: token });
            if (
              state.claims &&
              userClaims &&
              state.claims.iat < userClaims.updated.seconds
            ) {
              dispatch('idTokenGet', { refresh: true });
            }
          });
      },
      login: {
        root: true,
        async handler({ dispatch, commit }, { user, claims }) {
          commit('userSet', { user, claims });
          dispatch('claimsTrack', { uid: user.uid });
        },
      },
      logout: {
        root: true,
        handler({ commit }) {
          commit('userSet', { user: null, claims: null });
          if (unsub) unsub();
        },
      },
    },
  };
}
