<template>
  <router-link
    :to="toTr"
    :class="buttonClass"
    :data-tooltip="tooltipTr"
    :style="buttonStyle"
    :disabled="disabled"
  >
    <slot>
      <span v-if="icon" class="icon"><fa :icon="['fal', icon]" /></span>
      <span v-if="labelTr">{{ labelTr }}</span>
    </slot>
  </router-link>
</template>

<script>
import label from './mixins/label';
import color from './mixins/color';
import size from './mixins/size';
import tooltip from './mixins/tooltip';

export default {
  name: 'sqr-router-link',
  mixins: [label, tooltip, color, size],
  props: {
    icon: String,
    label: String,
    labelRaw: String,
    to: Object,
    toName: String,
    toId: String,
    isOutlined: Boolean,
    isLink: Boolean,
    disabled: Boolean,
  },
  computed: {
    toTr() {
      if (this.to) {
        return this.to;
      } else {
        let params = {};
        if (this.toId) {
          params.id = this.toId;
        }
        return { name: this.toName, params };
      }
    },
    buttonClass() {
      return Object.assign(
        {
          button: !this.isLink,
          'is-outlined': this.isOutlined,
        },
        this.colorClass,
        this.sizeClass,
        this.tooltipClass
      );
    },
    buttonStyle() {
      if (this.isFullwidth) {
        return 'justify-content: left';
      } else {
        return '';
      }
    },
  },
};
</script>
