import Vue from 'vue';
import buildRef from '@/utils/buildRef';

export default function () {
  let unsubscribe = {};
  return {
    namespaced: true,
    state: {
      pathes: {},
      loading: {},
      loadError: {},
      exists: {},
      docs: {},
    },
    getters: {},
    mutations: {
      loading(state, { key, path }) {
        Vue.set(state.pathes, key, path);
        Vue.set(state.loading, key, true);
        Vue.delete(state.loadError, key);
      },
      loaded(state, { key, exists, doc }) {
        Vue.set(state.exists, key, exists);
        if (doc) {
          Vue.set(state.docs, key, doc);
        } else {
          Vue.delete(state.docs, key);
        }
        Vue.delete(state.loading, key);
      },
      loadError(state, { key, error }) {
        Vue.set(state.loadError, key, error);
        Vue.delete(state.loading, key);
      },
      reset(state, { key }) {
        Vue.delete(state.pathes, key);
        Vue.delete(state.loading, key);
        Vue.delete(state.docs, key);
        Vue.delete(state.loadError, key);
      },
    },
    actions: {
      sub({ commit }, { pathes }) {
        if (typeof pathes !== 'object') {
          throw new Error('pathes must be an object');
        }
        const entries = Object.entries(pathes);
        entries.map(([key, path]) => {
          commit('loading', { key, path });
          const ref = buildRef(path);
          unsubscribe[key] = ref.onSnapshot(
            snap => {
              const exists = snap.exists;
              const doc = exists ? snap.data() : null;
              commit('loaded', { key, exists, doc });
            },
            error => commit('loadError', { key, error })
          );
        });
      },
      unsub({ commit }) {
        const entries = Object.entries(unsubscribe);
        entries.forEach(([key, unsubFn]) => {
          unsubFn();
          commit('reset', { key });
        });
        unsubscribe = {};
      },
    },
  };
}
