export default {
  locale: 'en',
  messages: {
    update_banner_title: 'WoTime Update',
    update_banner_loading: 'Loading update',
    update_banner_ready: 'WoTime update is ready',
    update_banner_updating: 'WoTime is updating',
    update_banner_action: 'Update Now',

    route_loading: 'Loading…',
    route_home: 'Home',
    route_launch: "Let's get started",
    route_start: 'Some Questions',
    route_login: 'Login',
    route_password_change: 'Password Change Request',
    route_password_reset: 'Password Set',
    route_register: 'Register',
    route_accounts: 'Accounts',
    route_database_dump: 'Backup a Database',
    route_company: 'Company Settings',
    route_settings: 'Settings',
    route_contract: 'Contract',
    route_planning: 'Planning',
    route_planning_holidays: 'Set Holidays',
    route_requests: 'Calendar Event Requests',
    route_organisation: 'Planning Organisation',
    route_company_rights: 'Vacations Rights',
    route_company_report_sheets: 'Company Sheets Report',
    route_company_report_vacations: 'Company Vacations Report',
    route_company_report_passages: 'Company Passages Report',
    route_subscription: 'Subscription',
    route_users: 'Users',
    route_user_add: 'Add an User',
    route_user_edit: 'User Update',
    route_user: 'User',
    route_sheets: 'Weeks',
    route_sheet: 'Work Week',
    route_passages: 'Passages',
    route_events: 'Calendar Events',
    route_event_add: 'Add an Event',
    route_events_holidays: '',
    route_event_edit: 'Event Update',
    route_rights: 'Vacation Rights',
    route_right_add: 'Add a Right',
    route_right_edit: 'Right Update',
    route_report_sheets: 'Work Weeks Report',
    route_report_vacations: 'Vacations Report',
    route_report_passages: 'Passages',
    route_about: 'About',
    route_logout: 'Logout',
    route_not_found: 'Feature Not Found',

    navbar_companies: 'Companies',
    navbar_companies_mgmt: 'View Companies',
    navbar_company_management: 'Management',
    navbar_company_planning: 'Planning',
    navbar_company_requests: 'Requests',
    navbar_company_rights: 'Vacation Rights',
    navbar_company_report_sheets: 'Work Report',
    navbar_company_report_vacations: 'Vacations Report',
    navbar_company_report_passages: 'Passages Report',
    navbar_company_subscription: 'Subscription',
    navbar_company_settings: 'Company Settings',
    navbar_company_accounts: 'Accounts',
    navbar_company_accounts_mgmt: 'Manage Accounts',
    navbar_company_accounts_recently: 'Recently visited',
    navbar_users: 'Users Management',
    navbar_account_sheets: 'Work Weeks',
    navbar_vacation_rights: 'Vacations Rights',
    navbar_passages: 'Passages',
    navbar_account_calendar: 'Calendar Events',
    navbar_rights: 'Vacations Rights',
    navbar_account_reports: 'Reports',
    navbar_report_work: 'Work Report',
    navbar_report_vacations: 'Vacations Report',
    navbar_report_passages: 'Passages Report',

    navbar_settings: 'Settings',
    navbar_account: 'Account & Settings',
    navbar_sign_in: 'Sign In',
    navbar_sign_up: 'Sign Up',
    navbar_sign_out: 'Sign Out',

    nav_companies_recent: 'Recent',
    nav_user_password_change: 'Change password',
    nav_user_about: 'About WoTime',

    breadcrumb_home: 'WoTime',
    breadcrumb_companies: 'companies',
    breadcrumb_company_planning: 'planning',
    breadcrumb_company_settings: 'settings',
    breadcrumb_company_name: 'name',
    breadcrumb_company_defaults: 'new account defaults',
    breadcrumb_company_models: 'time entries models',
    breadcrumb_company_extensions: 'extensions',
    breadcrumb_company_holidays: 'holidays',
    breadcrumb_company_requests: 'requests',
    breadcrumb_company_rights: 'vacations rights',
    breadcrumb_company_report_work: 'company work report',
    breadcrumb_company_report_vacations: 'company vacations report',
    breadcrumb_company_report_passages: 'company passages report',
    breadcrumb_orgs: 'organizations',
    breadcrumb_accounts: 'accounts',
    breadcrumb_account_add: 'add',
    breadcrumb_account_sheets: 'work weeks',
    breadcrumb_events: 'calendar events',
    breadcrumb_rights: 'vacation rights',
    breadcrumb_reports: 'reports',
    breadcrumb_report_sheets: 'work',
    breadcrumb_report_vacations: 'vacations',
    breadcrumb_settings: 'settings',
    breadcrumb_permissions: 'roles & access rights',

    buttons_back: 'Back',
    buttons_cancel: 'Cancel',
    buttons_save: 'Save',
    buttons_continue: 'Continue',
    buttons_reset: 'Reset',
    buttons_remove: 'Remove',
    buttons_load: 'Load',

    progress_loading: 'Loading…',
    progress_saving: 'Saving…',

    home_sign_in: 'Sign in to WoTime',
    home_sign_up: 'Register a new account',

    companies_start: 'Add a company',

    company_card_management: 'Management',
    company_card_sheets: 'Work Weeks',
    company_card_account: 'Account',

    company_requests_none: 'There are no future event request.',

    notify_event_past_title: 'Event from the past',
    notify_event_past_message:
      'You are updating an calendar event in the past, the matching work weeks have already been created. Please update the sheet entries.',

    start_profile_title: 'About you',

    start_company_title: "Let's get started!",
    start_company_subtitle: 'To track your overtime we need a few answers',
    start_company_name: "My company's name?",
    start_company_name_placeholder: 'Company name',
    start_company_work_model: 'I should work',
    start_company_work_model_42: '42 hours per week from Monday to Friday',
    start_company_work_model_41: '41 hours per week from Monday to Friday',
    start_company_work_model_40: '40 hours per week from Monday to Friday',
    start_company_work_model_other: 'other…',
    start_company_weekdays_worked: 'Weekdays worked',
    start_company_week_due:
      'How many hours of work are due, by contract, per week?',
    start_company_week_due_placeholder: 'hh:mm',
    start_company_week_due_help: 'Usually 40:00 or 41:00 or 42:00',
    start_company_week_conv: 'How many hours a day of work is worth?',
    start_company_week_conv_help:
      'If your work 40:00 hours per week (5 days) a day is worth 08:00 hours.<br>If your work 41:00 hours per week (5 days) a day is worth 08:12 hours. <br>If your work 42:00 hours per week (5 days) a day is worth 08:24 hours.',
    start_company_week_conv_placeholder: 'hh:mm',
    start_company_vacations: 'Days of vacation per year that I am entitled to',
    start_company_vacations_placeholder: 'days',
    start_company_vacations_help: 'Usually 20, 25 or 30 days per year',
    start_company_confirm: 'Start using WoTime!',

    signin_title: 'Sign in to WoTime',
    signin_subtitle: 'If you have problem login in please contact the support',
    signin_email: 'email',
    signin_password: 'password',
    signin_sign_in: 'Sign in',
    signin_sign_up: 'Create an account?',
    signin_lost: 'Lost password?',

    password_lost_title: 'Lost password',
    password_lost_email: 'email',
    password_lost_email_help:
      'you will receive an email with a link to change your password',
    password_lost_confirm: 'Send the link',
    password_lost_success: 'Please check your mailbox to change your password',

    password_change_title: 'Change password',
    password_change_password_old: 'current password',
    password_change_password_new: 'new password',
    password_change_password_confirm: 'confirm your new password',
    password_change_confirm: 'Change password',
    password_change_success: 'Votre mot de passe a bien été changé',

    signup_title: 'Register an Account',
    signup_subtitle: 'Please input your email and choose a password',
    signup_email: 'email',
    signup_email_required: 'please input you email address',
    signup_email_invalid: 'please check your email address',
    signup_password: 'password',
    signup_password_required: 'please choose and input a password',
    signup_password_length_min: 'password must be 8 or more characters',
    signup_given_name: 'given name',
    signup_given_name_required: 'please input your first name',
    signup_family_name: 'family name',
    signup_family_name_required: 'please input your family name',
    signup_sign_in: 'Already have an account?',
    signup_sign_up: 'Sign Up',

    tasks_title: 'Tasks',
    tasks_subtitle:
      'The tasks below allow you to perform the maintenance of your database.',
    tasks_continue: 'Continue',
    tasks_launch: 'Launch Task',

    task_sheets_generate_title: 'Generate Work Weeks',
    task_sheets_generate_subtitle:
      'Generate Work Weeks and Work Weeks Entries from passages and calendar events',
    task_sheets_generate_weeks: 'Number of weeks in the past to generate',
    task_sheets_generate_loaded: 'Loaded {count} {type}',
    task_sheets_generate_found: 'Found existing Work Week {id}',
    task_sheets_generate_new: 'Computing Work Week {id}',
    task_sheets_generate_unmodified: 'Work Week {id} has not been modified',
    task_sheets_generate_updated: 'Work Week {id} has been updated',

    task_sheets_compute_title: 'Recompute Work Weeks',
    task_sheets_compute_subtitle:
      'For all users recompute the Work Weeks totals',
    task_sheets_compute_weeks: 'Number of weeks to recompute',

    planning_title: 'Planning',
    planning_subtitle: 'All users events at one place',
    planning_current: 'current month',
    planning_accounts: 'Accounts',
    planning_org: 'Organization',
    planning_holidays: 'Add bank holidays to the planning',
    // planning_retro: 'Retroplanning',
    planning_month_current: 'current month',
    planning_retro: 'past events from work sheets',

    company_report_work_sheets_count: 'Count of work sheets',
    company_report_work_sheets_accepted: 'Count of Accepted work sheets',
    company_report_work_sheets_warning: 'Count of work sheets with warnings',
    company_report_work_sheets_error:
      'Count of work sheets with critical errors',

    company_holidays_year_choose: 'Choose a year',
    company_holidays_state_choose: 'Choose a canton',
    company_holidays_days_choose: 'Choose holidays',
    company_holidays_days_help:
      'Saturday and Sundays are not selected by default. Only add them is you consider Saturday or Sunday a work day.',
    company_holidays_accounts_choose:
      'Choose the accounts receiving the holidays',
    company_holidays_added: '✅ Holidays added to the planning',

    retro_planning_planning: 'Planning',

    requests_title: 'Calendar Event Requests',
    requests_subtitle: 'You can mark the events as validated',
    requests_empty: 'All requests have been fulfilled!',

    org_title: 'Users and Teams',
    org_subtitle: 'Organize the users and teams for the planning',
    org_name: 'Name',
    org_team_add: 'Add a Team',
    org_management_sheets_report_overtime_hide: 'Hide overtime',
    org_management_sheets_report_overtime_hide_help:
      'Do not show the time entries in the Management Work Report.',
    org_dropzone: 'Drop item here',
    org_dropzone_remove: 'Drop item here to remove it',

    company_rights_title: 'Vacations Rights',
    company_rights_subtitle: 'Update all Vacation Rights for a period at once',
    company_rights_account: 'Account',
    company_rights_week_year: 'Year',
    company_rights_week_start: 'From',
    company_rights_week_end: 'Until',
    company_rights_previous: 'Carry',
    company_rights_contract: 'by Contract',
    company_rights_total: 'Total',
    company_rights_accepted: 'Accepted',
    company_rights_carry_title: 'Carry vacations remainders from previous year',
    company_rights_actions: 'Actions',
    company_rights_create: 'Save',
    company_rights_new: 'Create new right',
    company_rights_carry: 'Carry to next year',

    company_report_sheets_title: 'Company Sheets Reports',
    company_report_sheets_title_alt:
      'Company Sheets Reports from {weekStart} to {weekEnd}',
    company_report_sheets_subtitle: 'Summarize the work weeks counters',
    company_report_sheets_changes: 'Some work sheet have been changed',
    company_report_sheets_reprocess: 'Recompute Report',
    company_report_sheets_employees: 'Employees',
    company_report_sheets_sheets: 'Sheets',
    company_report_sheets_accepted: 'Accepted',
    company_report_sheets_warning: 'Warning',
    company_report_sheets_error: 'Error',

    company_report_work_from: 'From week',
    company_report_work_until: 'Until week',
    company_report_work_build: 'Build report',

    company_report_vacations_week_from: 'From week',
    company_report_vacations_week_until: 'Until week',
    company_report_vacations_planned_until: 'Vacations planned until',
    company_report_vacations_build: 'Build report',

    company_report_vacations_title: 'Company Vacations Reports',
    company_report_vacations_title_alt:
      'Company Vacations Reports from {weekStart} to {weekEnd}',
    company_report_vacations_subtitle:
      'Summarize the remaining vacations rights',
    company_report_vacations_changes:
      'Some work sheets or vacation right have been changed',
    company_report_vacations_reprocess: 'Recompute Report',
    company_report_vacations_employees: 'Employees',
    company_report_vacations_sheets: 'Sheets',
    company_report_vacations_accepted: 'Accepted',
    company_report_vacations_initial: 'Initial',
    company_report_vacations_used: 'Used',
    company_report_vacations_remaining: 'Remaining',

    company_report_vacations_edit: 'Edit all rights',
    company_report_vacations_prepare: 'Prepare next year rights',

    company_report_passages_title: 'Passages Company Report',
    company_report_passages_subtitle: 'Check the passages for a day',
    company_report_passages_changes: 'Some passages have been changed',
    company_report_passages_reprocess: 'Recompute Report',
    company_report_passages_date: 'Passages Date',
    company_report_passages_employees: 'Employees',
    company_report_passages_passages: 'Passages',
    company_report_passages_ignored: 'Discrepancies',
    company_report_passages_work_week: 'Work Week',
    company_report_passages_events: 'Events',

    subscription_title: 'Subscription',
    subscription_subtitle:
      'Check your subscription details and update your payment information',

    subscription_subscription: 'Subscription',
    subscription_status: 'Status',
    subscription_cost: 'Cost',
    subscription_users_current: 'Current Users',
    subscription_user: 'user',
    subscription_month: 'month',
    subscription_users: 'user active in the month | users active in the month',
    subscription_trial_period: 'Trial Period',
    subscription_trial_period_days: 'days',
    subscription_trial_end: 'End of trial',
    subscription_warranty: 'Warranty',
    subscription_warranty_text: '100 % satisfied or reimbursed',
    subscription_terms: 'display the term & conditions',

    subscription_invoices_title: 'Invoices',
    subscription_invoices_number: 'Number',
    subscription_invoices_date: 'Date',
    subscription_invoices_status: 'Status',
    subscription_status_past_due: 'past due',
    subscription_invoices_status_paid: 'Paid',
    subscription_invoices_due_date: 'Due',
    subscription_invoices_period_start: 'Period From',
    subscription_invoices_period_end: 'Period Until',
    subscription_invoices_amount_due: 'Amount Due',
    subscription_invoices_amount_paid: 'Amount Paid',
    subscription_invoices_currency: 'Currency',

    subscription_cc_title: 'Credit Card',
    subscription_cc_brand: 'Brand',
    subscription_cc_number: 'Last Digits',
    subscription_cc_expires: 'Expires',
    subscription_cc_usage: 'Usage',
    subscription_cc_actions: 'Actions',
    subscription_cc_default: 'used for charges',
    subscription_cc: 'Credit Card',
    subscription_cc_add: 'Add Credit Card',
    subscription_cc_add_error:
      'Temporary error while adding the credit card, please try again later',
    subscription_cc_remove: 'Remove',

    terms_title: 'Terms & Conditions',
    terms_subtitle:
      'The terms and conditions are in french only. Please contact us if you are in need of a translation.',

    company_tag: 'Company',
    company_title: 'Company Settings',

    company_management_planning: 'Company Planning',
    company_planning: 'Planning',
    company_planning_help: 'view all the calendar events for all employees',
    company_requests: 'Requests',
    company_requests_help:
      'Accept / deny all calendar events proposed by employees',
    company_holidays: 'Holidays',
    company_holidays_help: 'Add bank holidays to planning by canton',
    company_rights: 'Vacation Rights',
    company_rights_help: 'Update all employees vacation rights all at once',

    company_management: 'Management',

    company_reports: 'Management reports',
    company_report_sheets: 'Work report',
    company_report_sheets_help:
      'Summary of all the work weeks counters for all employees',
    company_report_vacations: 'Vacations report',
    company_report_vacations_help:
      'Summary of the vacations the remaining and planned for all employees',
    company_report_passages: 'Passages report',
    company_report_passages_help: 'Check the passages of all employee by day',
    company_audit_trail: 'Audit trail',
    company_audit_trail_help: 'Records change tracking',

    company_accounts_title: 'Accounts',
    company_accounts: 'Manage accounts',
    company_accounts_help:
      'Add a new account, change the account role and accesses',
    company_orgs: 'Organize accounts and teams',
    company_orgs_help: 'Group accounts using teams',
    company_org: 'Organize accounts',
    company_org_help: 'Group accounts using teams',
    company_integrations: 'Integrations',
    company_integrations_help: 'Keycards, phones, etc',

    company_settings: 'Company settings',
    company_settings_help:
      'Update your company name, the time entries model, and the extentions',
    company_settings_name: 'Company name',
    company_settings_name_help: 'Change the name of your company',
    company_settings_defaults: 'New accounts settings',
    company_settings_defaults_help: 'Defaults work week and vacations settings',
    company_settings_models: 'Work weeks entries',
    company_settings_models_help: 'Change the presentation of the entries',
    company_settings_extensions: 'Extensions',
    company_settings_extensions_help:
      'Different work time accounting and checks',

    company_subscription: 'Subscriptions',
    company_subscription_help: 'Payments method and invoices',

    company_name: 'Company Name',

    company_contract_form: 'form',
    company_contract_capital: 'capital',
    company_contract_ide: 'IDE',
    company_contract_street: 'street',
    company_contract_postal_code: 'postal code',
    company_contract_city: 'city',
    company_contract_contact: 'contact',
    company_contract_position: 'position',
    company_contract_label: 'label',
    company_contract_price: 'price',
    company_contract_email: 'email',
    company_contract_currency: 'currency',
    company_contract_created: 'created',

    company_extension_overlaps: 'Check overlapping work week entries',
    company_extension_overlaps_help:
      'Check if two time entries are overlapping on the same day.',
    company_extension_ltr15: 'Article 15',
    company_extension_ltr15_help: `Apply mandatory pauses described in article 15 of Swiss Labor Law. When work > 9 hours then 1 hour of pause, when work > 7 hours then 30 minutes of pause, and when work > 5.5 hours -> 15 minutes of pause`,

    company_extension_pause30: 'Pause <30',
    company_extension_pause30_help:
      'Warn the user when he is not documenting the start and end of pauses superior or equal to 30 minutes',

    'company_extension_ltr15-wc': 'Article 15 with margins (Webcor)',
    'company_extension_ltr15-wc_help':
      'Apply mandatory pauses described in article 15 of Swiss Labor Law with some margins. When work > 9.5 hours then 1 hour of pause, when work > 7.5 hours then 30 minutes of pause, and when work > 5.75 hours -> 15 minutes of pause',

    company_entry_models: 'Work weeks entries models',
    company_entry_models_help:
      'Customize the colors of the entries that will be displayed on the planning and in the work weeks view',

    company_extensions: 'Extensions',
    company_extensions_help:
      'Choose which extension will apply to timesheet computation. If you need another extension please get in touch.',

    company_account_week_settings: 'New account Week Settings',
    company_account_week_settings_help:
      'When a new account is created the defaults below will be used',

    trail_timestamp: 'Timestamp',
    trail_user: 'User',
    trail_account: 'Account',
    trail_record: 'Collection',
    trail_id: 'Record',
    trail_op: 'Operation',
    trail_actions: 'Actions',
    trail_restore: 'restore',

    users_title: 'Users Management',
    users_subtitle: 'Add new user, give the user access',
    user: 'User',

    accounts_org: 'Edit',
    accounts_org_view: 'Organized accounts',
    accounts_org_all: 'All accounts',
    accounts_settings: 'Settings',
    accounts_archive: 'Archive',
    weekday_entry_from: 'from',
    weekday_entry_until: 'until',
    weekday_entry_day: 'day',

    account_sheets: 'Work Sheets',
    account_sheets_help: 'Input and check your work time',
    account_events: 'Calendar events',
    account_events_help: 'Plan for vacations check bank holidays',
    account_rights: 'Vacation Rights',
    account_rights_help:
      'Record the vacations rights by contract and remaining from last year',
    account_reports: 'Reports',
    account_report_work: 'Work report',
    account_report_work_help: 'Summary of all your counters for the year',
    account_report_vacations: 'Vacations report',
    account_report_vacations_help: 'Summary of your vacations for the year',
    account_report_passages: 'Passages report',
    account_report_passages_help: 'See when you punch-in and punch-out of work',
    account_settings: 'Settings',
    account_settings_help: 'Change general, week and year settings',
    account_permissions: 'Roles & access rights',
    account_permissions_help:
      'Manager role, Employee role, read and write accesses',

    account_add_title: 'Add an User',
    account_add_subtitle: 'Invite an user to use WoTime',
    account_add_email: 'email',
    account_add_given_name: 'Given name',
    account_add_family_name: 'Family name',
    account_add_email_help:
      'The user will receive an email inviting him to WoTime on your behalf. This email includes instructions on how to connect.',
    account_add_uid: 'User ID',
    account_add_password: 'Password',
    account_add_confirm: 'Create account',

    integrations_title: 'Integrations',

    int_centaur_title: 'Keycards management',
    int_centaur_change: 'Change',
    int_centaur_remove: 'Remove',
    int_centaur_add: 'Add a Keycard',
    int_centaur_keycard_name: 'Name on the Keycard?',
    int_centaur_accounts: 'Keycards to Accounts',
    int_centaur_tech: 'Display technical details',
    int_centaur_last_id: 'Last entry imported',
    int_centaur_url: 'URL for WoTime Gates',
    int_centaur_secret: 'Secret',

    accounts_add: 'Create an account',
    users_view_archived: 'view archived users',

    sheets_title: 'Work Weeks for {name}',
    sheets_subtitle:
      'Work Weeks allow you to track by week your work periods and vacations',
    sheets_view_archived: 'View archived work weeks',
    sheets_index_refresh: 'Index',

    sheet_card_work_week: 'Work Week',
    sheet_card_from: 'from',
    sheet_card_to: 'to',
    sheet_card_accepted: 'Accepted',

    sheet_remove_confirm:
      'Are you sure you want to remove the work week {name}?',

    sheet_settings_due: 'Work due',
    sheet_settings_due_cap:
      'Are you sure? This value is above typical work due during a week.',
    sheet_settings_max: 'Max work',
    sheet_settings_max_cap:
      'Are you sure? This value is above the max legal time (from 40 to 45 hours).',
    sheet_settings_conv: 'One day is',

    entry_span_from: 'hh:mm',
    entry_span_until: 'hh:mm',
    entry_model_modal_filter: 'filter the models…',
    entry_note: 'Note',

    entry_model_picker_search: 'search…',

    period_buttons_label: 'Periods',

    sheets_work_week: 'Work Week',
    sheets_accept: 'Accept',
    sheets_archive: 'Archive',
    sheets_unarchive: 'Unarchive',
    sheets_create_first: 'Create the first work week',
    sheets_create_next: 'Add {week} Work Week',
    sheets_create_previous: 'Add {week} Work Week',

    sheet_title: 'Work Week',
    sheet_subtitle:
      'Describe your work periods, pauses, vacations and holidays',
    sheet_validated:
      'This work week is tagged as validated. If you make some changes it will loose its tag..',
    sheet_copy_previous: 'Copy entries from previous',
    sheet_generate: 'Refresh entries',
    sheet_generate_tooltip: 'Generate entries from passages & events',
    sheet_versions: 'Previous versions',
    account_sheet_create: 'Create Work Week',

    update_info_by: 'updated by',
    update_info_on: 'on',
    update_progress_offline:
      'You are offline. Your updates will be transmitted as soon as you get back online',

    sheet_revs_title: 'Work week versions',
    sheet_revs_subtitle:
      'You can rollback to a former version of the work week.',
    sheets_revs_rev: 'Display version',
    sheet_revs_restore: 'Restore this version',

    week_day_more: 'more',

    week_total: 'Totals',
    sheet_summary_inputs: 'What was inputed in this sheet',
    sheet_summary_computed: 'And what was computed for this sheet',
    week_summary: 'Summary',
    week_settings: 'Week Settings',

    entry_duration_placeholder: 'hh:mm',

    events_title: 'Calendar Events',
    events_subtitle: 'Calendar Events help plan and later fill your work weeks',
    events_planning: 'Planning',
    events_add: 'Add an Event',
    event_add_vacations: 'Add a Vacation',
    events_holidays_add: 'Add bank holidays for your region',
    events_view_past_events: 'view past events',

    event_tag: 'Event',
    event_card_event: 'Event',
    event_card_accept: 'Accept',

    // holidays_title: 'Add bank holidays for your region',
    // holidays_subtitle: 'Please select the region, city and observance',
    // holidays_info_single: 'You are adding bank holidays for one single user.',
    // holidays_info_also: 'You can also add a bank holidays {0}.',
    // holidays_info_all: 'for all employee',
    // holidays_state: 'Please choose the swiss canton',
    // holidays_year: 'Year',
    // holidays_add_all: 'Add all holidays listed below',

    // event_holiday_choose: 'Choose a canton',

    // events_holidays_check_title: 'Holidays',
    // events_holidays_check_subtitle: 'Holidays and Non Working days for all Cantons',
    // events_holidays_check_label: 'Label',
    // events_holidays_check_date: 'Date',
    // events_holidays_check_type: 'Type',

    event_per_day: 'per day',

    event_title: 'Calendar Event',
    event_subtitle: 'Calendar Events help plan and later fill your work weeks',
    event_save: 'Save Event',
    event_remove: 'Remove',
    event_remove_confirm: 'Are you sure you want to remove the event {name}?',
    event_remove_all: 'Remove all',
    event_remove_all_confirm:
      'Are you sure you want to remove the events {name} for all accounts?',

    event_all_day: 'All day event',
    event_all_day_text: 'All day event can span to several days',

    event_modal_all_accounts_create: 'Create for all accounts',
    event_modal_all_accounts_create_description:
      'create an event for all accounts',

    event_account: 'Account',
    event_start_date: 'From',
    event_end_date: 'Until',

    event_start_time: 'From',
    event_end_time: 'Until',

    event_entry: 'Entry Type',
    event_entry_pick: 'Choose an entry type',
    event_weekday_exception: 'Event will not apply on',
    event_weekday_exception_mon: 'Mo',
    event_weekday_exception_tue: 'Tu',
    event_weekday_exception_wed: 'We',
    event_weekday_exception_thu: 'Th',
    event_weekday_exception_fri: 'Fr',
    event_weekday_exception_sat: 'Sa',
    event_weekday_exception_sun: 'Su',

    event_attachments: 'Attachments',
    event_attachments_upload: 'Upload attachment',
    event_note: 'Note',
    event_status: 'Validation status',

    right_card_label: 'Vacation right',

    rights_title: 'Vacations Rights',
    rights_subtitle:
      'Vacation rights enable you to track your vacation usage per period',
    right_from: 'from',
    right_until: 'until',
    right_part_from: 'from',
    right_part_until: 'until',
    right_part_days: 'days',
    right_part_base: 'base',
    right_part_vacations: 'vacations',
    right_part_add: 'add a new period',
    rights_add: 'Add a vacation right',

    right: 'Vacation Right',
    right_edit_title: 'Vacation Right',
    right_edit_subtitle:
      'Vacation rights enable you to track your vacation usage per period',
    right_start_date: 'From',
    right_end_date: 'Until',
    right_week_year: 'Year',
    right_vacations_contract: 'Vacations right for the year (days)',
    right_vacations_contract_short: 'Period',
    right_vacations_previous: 'Remainder from previous year (days)',
    right_vacations_previous_short: 'Previous',
    right_vacations: 'Total vacation right',
    right_compute: 'Compute',
    right_vacations_short: 'Total',
    right_vacations_computed: 'Computed',
    right_status: 'Validation status',
    right_note: 'Note',
    right_save: 'Save Right',
    right_remove: 'Remove',

    reports_actions: 'Set periods',
    reports_summaries: 'Work Week Summaries',
    reports_vacations: 'Vacations',
    reports_passages: 'Passages',

    report_sheets_title: 'Work Weeks Report',
    report_sheets_subtitle: 'Summarize the work weeks counters',
    report_sheets: 'Work Weeks Report',
    report_sheets_start_date: 'From',
    report_sheets_end_date: 'Until',
    report_sheets_details: 'Details',
    report_sheets_details_help: 'display all the sheet entries',
    report_sheets_sheets: '{weeks} week| {weeks} weeks',
    report_sheets_accepted: 'accepted',
    report_sheets_from: 'from',
    report_sheets_until: 'until',

    report_vacations_title: 'Vacations Report',
    report_vacations_for: 'for',
    report_vacations_subtitle: 'Summarize the remaining vacations rights',
    report_vacations_sheets: '{weeks} week| {weeks} weeks',
    report_vacations_start_week: 'From week',
    report_vacations_end_week: 'Until week',
    report_vacations_end_right: 'Count planned vacations until',
    report_vacations_end_right_help:
      'count the vacations that are planned on the calendar and not yet used',
    report_vacations_details: 'Details',
    report_vacations_details_help: 'display entries used for computation',
    report_vacations: 'Vacations Report',
    report_vacations_start_date: 'From',
    report_vacations_end_date: 'Until',
    report_vacations_right: 'Right',
    report_vacations_usage: 'Usage',
    report_vacations_week: 'Week',
    report_vacations_day: 'Day',
    report_vacations_amount: 'Amount',
    report_vacations_note: 'Note',
    report_vacations_op_right: 'Vacation right',
    report_vacations_op_used: 'Used',
    report_vacations_op_planned: 'Planned',
    report_vacations_event_date: 'Date',
    report_vacations_event_amount: 'Vacations',
    report_vacations_event_note: 'Note',
    report_vacations_op_remaining: 'Remaining',

    report_passages_title: 'Passages Report',
    report_passages_subtitle: 'Check the passages for the current week',
    report_passages_week: 'Week',
    report_passages_not_found: 'No passage was found for this week.',

    account_title: 'User',
    account_title_alt: 'User {firstName} {lastName}',

    account_general: 'General',
    account_given_name: 'Given name',
    account_family_name: 'Family name',
    account_email: 'email',
    account_email_help: 'to change en email address please contact the support',
    account_role: 'Role',
    account_roles_user: 'employee',
    account_roles_user_help: 'can only access his own data',
    account_roles_manager: 'manager',
    account_roles_manager_help: 'can access all employees data',
    account_access: 'Access',
    account_accesses_rw: 'read & write',
    account_accesses_rw_help: 'can view and update the data',
    account_accesses_ro: 'read only',
    account_accesses_ro_help: 'can only view the data',
    account_planning: 'Planning',
    account_planning_help: 'Read only access to the company planning',

    account_week_settings: 'Week Settings',
    account_week_settings_simple: 'simple',
    account_week_settings_alternate: 'alternate',
    account_week_settings_help:
      'The values below will be used next time you create a Work Week.',
    account_week_settings_due: 'Work due per week',
    account_week_settings_due_odd: 'Work due on odd weeks (W01, W03…)',
    account_week_settings_due_even: 'Work due on even weeks (W02, W04…)',
    account_week_settings_max: 'Max work per week',
    account_week_settings_max_helper:
      'When you work more than the max work amount, the time is counted as extra-time',
    account_week_settings_conv: 'One day of work in hours',
    account_year_settings: 'Year Settings',
    account_year_settings_help:
      'The values below will be used next time you create a vacation right',
    account_year_settings_vacations: 'How many days of vacations per year?',

    account_keycards_settings: 'Keycards',
    account_keycards_settings_help:
      'Automaticaly create passages from the keycard gate events',
    account_keycards: 'Keycards',
    account_keycards_help: 'input one keycard name per line',

    passages_title: 'Passages',
    passages_subtitle:
      'Get your work time fill automaticaly by setting up passages from your iPhone or Android phone.',
    passages_phone_connect:
      'You must be connected from the application on your phone to setup the passages',

    settings_title: 'Settings',
    settings_subtitle: '',

    permissions_account: 'Account',
    permissions_active: 'active',
    permissions_archived: 'archived',
    permissions_roles: 'Roles',
    permissions_manager: 'manager',
    permissions_employee: 'employee',
    permissions_planning: 'planning view',
    permissions_access: 'Access right',
    permissions_ro: 'read only',
    permissions_rw: 'read & write',
    permissions_claims_refresh: 'Refresh Claims',

    not_found_title: 'Not Found',
    not_found_subtitle: 'The ressource can not be found.',

    error_report_title: 'Error',
    error_title: 'Oops, an error occured :-(',

    about_bc: 'WoTime',
    about_title: 'About WoTime',
    about_subtitle:
      'Please find all the details of the current version of WoTime',
    about_version: 'Version',
    about_build_date: 'Built on',
    about_support: 'Contact support',

    auth_bc: 'authentification',
    auth_user: 'user',
    auth_claims: 'claims',
    auth_custom_login: 'Sign in',

    // translations view
    translations_title: 'Translations',
    translations_subtitle: 'List of all WoTime translations',
    translations_key: 'key',
    translations_en: 'English',
    translations_fr: 'Français',
    translations_de: 'Deutsch',

    account_pick_title: 'Pick an account',

    company_name_placeholder: 'Company',

    // units
    days: 'day | days',
    days_short: 'd',

    // weekdays component
    weekdays_1_short: 'Mon',
    weekdays_2_short: 'Tue',
    weekdays_3_short: 'Wed',
    weekdays_4_short: 'Thu',
    weekdays_5_short: 'Fri',
    weekdays_6_short: 'Sat',
    weekdays_7_short: 'Sun',

    weekdays_1_long: 'Monday',
    weekdays_2_long: 'Tuesday',
    weekdays_3_long: 'Wednesday',
    weekdays_4_long: 'Thursday',
    weekdays_5_long: 'Friday',
    weekdays_6_long: 'Saturday',
    weekdays_7_long: 'Sunday',

    // error codes for firebase
    firebase_auth_email_already_in_use:
      'The email address is already registered by another account.',
    firebase_auth_invalid_email: 'This e-mail address is not valid.',
    firebase_auth_operation_not_allowed: 'This operation cannot be performed',
    firebase_auth_weak_password:
      'Password is too weak and can be guessed too easily',
    firebase_auth_user_disabled: 'This user account is disabled',
    firebase_auth_user_not_found: 'User account not found',
    firebase_auth_wrong_password: 'incorrect password',
    firebase_auth_too_many_requests:
      'too many requests were made, please try again later',

    // dnd component
    sqr_fire_upload_dnd_available: 'Drag & drop files to upload',

    validation_email: 'This is not a valid email address',
    validation_required: 'This field is required',
    validation_optional: 'This is optional',

    // custom partner page
    partner_stsa_register_title: 'STSA Members',
    partner_stsa_register_subtitle:
      'STSA members benefit from a discount of 30 %. The price of a user per month is 6 CHF (instead of 9 CHF) • 30 free trial• 100 % satisfied or refunded',

    // statuses for sheets, events, rights, accounts
    statuses_pending: 'Pending',
    statuses_pending_select: 'Unlock',
    statuses_accepted: 'Accepted',
    statuses_accepted_select: 'Accept',
    statuses_denied: 'Denied',
    statuses_denied_select: 'Deny',
    status_locked:
      'If you want to edit this record you will lose its validation status. Are you sure you want to unlock the record?',
    status_locked_event:
      'If you want to edit this event you will lose its validation status. Are you sure you want to unlock the event?',
    status_unlocked: 'Unlock',

    // pagination component
    pagination_next: 'next page',
    pagination_previous: 'previous',
    pagination_goto: 'page {page}'
  }
};
