<template>
  <nav
    class="navbar is-fixed-top has-shadow"
    role="navigation"
    aria-label="main navigation"
  >
    <div class="navbar-brand">
      <router-link :to="{ name: 'home' }" class="navbar-item">
        <Logo :width="32" />
        <span class="font-semibold" style="margin-left: 0.75rem">WoTime</span>
      </router-link>
      <a
        role="button"
        class="navbar-burger burger"
        aria-label="menu"
        aria-expanded="false"
        @click="active = !active"
      >
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>

    <div
      class="navbar-menu"
      :class="{ 'is-active': active }"
      @click="active = false"
    >
      <div class="navbar-start">
        <nav-companies />
        <nav-management />
        <nav-accounts />

        <nav-account-sheets />
        <nav-account-calendar />
        <nav-rights />
        <nav-account-reports />
      </div>

      <div class="navbar-end" v-if="!authenticated">
        <nav-locales />
        <div class="navbar-item">
          <div class="buttons">
            <sqr-router-link
              to-name="sign-in"
              label="navbar_sign_in"
              color="light"
            />
            <sqr-router-link
              to-name="sign-up"
              label="navbar_sign_up"
              color="primary"
            />
          </div>
        </div>
      </div>
      <!-- when authenticated -->
      <div class="navbar-end" v-if="authenticated">
        <nav-locales />
        <nav-user />
      </div>
    </div>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex';
import SqrRouterLink from '@/sqrd/SqrRouterLink';
import NavCompanies from '@/components/NavCompanies';
import NavManagement from '@/components/NavManagement';
import NavAccounts from '@/components/NavAccounts';
import NavAccountSheets from '@/components/NavAccountSheets';
import NavAccountCalendar from '@/components/NavAccountCalendar';
import NavAccountReports from '@/components/NavAccountReports';
import NavRights from '@/components/NavRights';
import NavLocales from '@/components/NavLocales';
import NavUser from '@/components/NavUser';
// import {RouterLink} from 'vue-router';
export default {
  name: 'NavBar',
  components: {
    Logo: () => import(/* webpackChunkName: "Logo" */ '@/components/Logo.vue'),
    NavCompanies,
    NavManagement,
    NavAccounts,
    NavAccountSheets,
    NavAccountCalendar,
    NavAccountReports,
    NavRights,
    NavLocales,
    NavUser,
    SqrRouterLink,
  },
  data() {
    return {
      active: false,
    };
  },
  computed: {
    ...mapGetters('auth', ['authenticated']),
  },
};
</script>
