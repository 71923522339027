import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/firebase-auth';
import 'firebase/firebase-firestore';
import 'firebase/firebase-functions';
import 'firebase/firebase-storage';
// import 'firebase/performance';
// import 'firebase/firebase-analytics';
// import 'firebase/firebase-remote-config';

export default function (Vue) {
  // conf
  var firebaseConfig = {
    apiKey: 'AIzaSyBLjjXkP4kNMzkrkf6ElEsnO_98DOkuMiI',
    authDomain: 'sqrd-wotime.firebaseapp.com',
    databaseURL: 'https://sqrd-wotime.firebaseio.com',
    projectId: 'sqrd-wotime',
    storageBucket: 'sqrd-wotime.appspot.com',
    messagingSenderId: '984937830038',
    appId: '1:984937830038:web:3871eccce1ae76e3c114b7',
    measurementId: 'G-80REC5EJ0M',
  };
  const app = firebase.initializeApp(firebaseConfig);

  let functions = app.functions('europe-west1');
  if (process.env.VUE_APP_EMULATE) {
    app.auth().useEmulator('http://localhost:9099/');
    app.firestore().useEmulator('localhost', 6060);
    functions.useEmulator('localhost', 5001);
    // eslint-disable-next-line no-console
    console.log('emulation activated');
  }

  firebase.analytics();
  Vue.$fa = () => app.analytics();
  Vue.prototype.$fa = () => app.analytics();

  app.auth().languageCode = 'en';

  firebase.firestore().enablePersistence({ synchronizeTabs: true });
  Vue.prototype.$db = () => app.firestore();
  Vue.$db = () => app.firestore();

  Vue.prototype.$fb = () => app;
  Vue.$fb = () => app;

  Vue.prototype.$f = name => functions.httpsCallable(name);
  Vue.$f = name => functions.httpsCallable(name);
}
