<template>
  <span v-if="account">
    <span v-if="!reverse">{{ account.givenName }} </span>
    <span class="font-medium">{{ account.familyName }}</span>
    <span v-if="reverse && account.givenName">, {{ account.givenName }}</span>
    <span v-if="!account.givenName && !account.familyName && account.email">
      {{ account.email }}
    </span>
    <span v-if="!account.givenName && !account.familyName && !account.email">
      {{ account.id || 'N/A' }}
    </span>
  </span>
</template>

<script>
export default {
  name: 'AccountName',
  props: {
    account: { type: Object },
    reverse: Boolean
  }
};
</script>
